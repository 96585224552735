import { Ticket, Trip, TripCategory } from "types";
import {
  moovsBlue,
  successGreen,
  grayDark,
} from "globals/design-system/colors";
import first from "lodash/first";

const statusBarColorUtil = (ticket: Ticket, trip: Trip) => {
  // derived state
  const pickupStopIndex =
    ticket.trip.tripCategory === TripCategory.ShuttlePickUp
      ? ticket.stop.stopIndex
      : 1; // return trips always pickup from first stop

  const { currentStop } = first(trip.routes).routeDispatch;

  // driver is on an earlier stop than the ticket pick up stop
  if (pickupStopIndex > currentStop.stopIndex) {
    return moovsBlue;
  }

  // driver is on an later stop than the ticket pick up stop
  if (pickupStopIndex < currentStop.stopIndex) {
    return grayDark;
  }

  // driver is heading to or has arrived at pick up stop
  return successGreen;
};

export default statusBarColorUtil;
