import React from "react";

type ArrowRoundTripIconProps = {
  color?: string;
};

const ArrowRoundTripIcon = (props: ArrowRoundTripIconProps) => {
  const { color } = props;

  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.49127 7.9987V12.102C4.49127 12.7819 4.7408 13.4339 5.18496 13.9146C5.62913 14.3953 6.23154 14.6654 6.85969 14.6654H13.965M4.49127 7.9987L7.64916 11.332M4.49127 7.9987L1.33337 11.332M16.5088 7.9987L16.5088 3.89535C16.5088 3.21551 16.2593 2.56352 15.8151 2.08281C15.371 1.60209 14.7685 1.33203 14.1404 1.33203L7.03513 1.33203M16.5088 7.9987L13.3509 4.66536M16.5088 7.9987L19.6667 4.66536"
        stroke={color || "#565656"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRoundTripIcon;
