// vehicle images at every 30 degree angle
import vehicle330 from "globals/design-system/illustrations/driverTracking/vehicle330.svg";
import vehicle300 from "globals/design-system/illustrations/driverTracking/vehicle300.svg";
import vehicle270 from "globals/design-system/illustrations/driverTracking/vehicle270.svg";
import vehicle240 from "globals/design-system/illustrations/driverTracking/vehicle240.svg";
import vehicle210 from "globals/design-system/illustrations/driverTracking/vehicle210.svg";
import vehicle180 from "globals/design-system/illustrations/driverTracking/vehicle180.svg";
import vehicle150 from "globals/design-system/illustrations/driverTracking/vehicle150.svg";
import vehicle120 from "globals/design-system/illustrations/driverTracking/vehicle120.svg";
import vehicle90 from "globals/design-system/illustrations/driverTracking/vehicle90.svg";
import vehicle60 from "globals/design-system/illustrations/driverTracking/vehicle60.svg";
import vehicle30 from "globals/design-system/illustrations/driverTracking/vehicle30.svg";
import vehicle0 from "globals/design-system/illustrations/driverTracking/vehicle0.svg";

export const setIconPathByRotation = (rotation: number) => {
  if (rotation >= 345 && rotation <= 15) {
    return vehicle0;
  } else if (rotation >= 15 && rotation <= 45) {
    return vehicle30;
  } else if (rotation >= 45 && rotation <= 75) {
    return vehicle60;
  } else if (rotation >= 75 && rotation <= 105) {
    return vehicle90;
  } else if (rotation >= 105 && rotation <= 135) {
    return vehicle120;
  } else if (rotation >= 135 && rotation <= 165) {
    return vehicle150;
  } else if (rotation >= 165 && rotation <= 195) {
    return vehicle180;
  } else if (rotation >= 195 && rotation <= 225) {
    return vehicle210;
  } else if (rotation >= 225 && rotation <= 255) {
    return vehicle240;
  } else if (rotation >= 255 && rotation <= 285) {
    return vehicle270;
  } else if (rotation >= 285 && rotation <= 315) {
    return vehicle300;
  } else if (rotation >= 315 && rotation <= 345) {
    return vehicle330;
  } else {
    return vehicle0;
  }
};
