import first from "lodash/first";
import { RouteDispatchLogStatusEnum, Ticket, Trip, TripCategory } from "types";

const driverTextUtil = (ticket: Ticket, trip: Trip) => {
  // derived state
  const pickupStopIndex =
    ticket.trip.tripCategory === TripCategory.ShuttlePickUp
      ? ticket.stop.stopIndex
      : 1; // return trips always pickup from first stop

  const { currentStop, currentStatus } = first(trip.routes).routeDispatch;

  // driver is on an earlier stop than the ticket pick up stop
  if (pickupStopIndex > currentStop.stopIndex) {
    return "Driver is";
  }

  // driver is on an later stop than the ticket pick up stop
  if (pickupStopIndex < currentStop.stopIndex) {
    return "Driver";
  }

  // driver is heading to pick up stop
  if (currentStatus === RouteDispatchLogStatusEnum.EnRoute) {
    return "Driver heading to";
  }

  // driver has arrived at pick-up stop
  return "Driver";
};

export default driverTextUtil;
