import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button, CircularProgress, Typography, Box } from "@mui/material";

import { LogoHeader } from "components/layout/components";
import { ArrowLeftIcon } from "globals/design-system/icons";
import { useLoadCompanyForUnauthenticated } from "globals/hooks";
import { grayDark, grayLight, moovsBlue } from "globals/design-system/colors";
import VerifyCodeInput from "./components/VerifyCodeInput";

// constants
const VERIFY_CODE_PAGE_WIDTH = "852px";

function VerifyCodePage() {
  // state
  const [loading, setLoading] = useState(false);

  // hooks
  const { unauthenticatedCompany } = useLoadCompanyForUnauthenticated();
  const history = useHistory<{ maskedPhone: string }>();

  const { maskedPhone } = history.location.state;

  // event handlers
  const handleClickBack = () =>
    history.push(`/${unauthenticatedCompany.id}/login`);

  // useEffect
  // if user refreshes page, redirect user to login page
  useEffect(() => {
    if (!maskedPhone && unauthenticatedCompany.id) {
      history.push(`/${unauthenticatedCompany.id}/login`);
    }
  }, [maskedPhone, unauthenticatedCompany, history]);

  return (
    <>
      {/* page header */}
      <LogoHeader />
      {/* Page Body */}
      <Box display="flex" justifyContent="center">
        <Box maxWidth={VERIFY_CODE_PAGE_WIDTH} width="100%">
          <Box borderBottom={`1px solid ${grayLight}`} pl={2} pr={2}>
            {/* Navigate to previous page */}
            <Box mt={2} mb={1.5}>
              <Button
                sx={{ paddingLeft: 0 }}
                onClick={handleClickBack}
                color="primary"
                startIcon={<ArrowLeftIcon color={moovsBlue} size="small" />}
              >
                Back
              </Button>
            </Box>

            {/* Confirm Text */}
            <Box mb={2}>
              <Box mb={1}>
                <Typography variant="h3">Confirm your phone</Typography>
              </Box>
              <Typography
                variant="body2"
                fontSize="16px"
                color={grayDark}
                display="inline"
              >
                Code sent to{" "}
                <Typography variant="subtitle2" display="inline">
                  {maskedPhone}
                </Typography>
              </Typography>
            </Box>
          </Box>

          {/* Verify Code Input */}
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="50vh"
            >
              <CircularProgress size={60} />
            </Box>
          ) : (
            <Box mt="130px" display="flex" justifyContent="center" mb={4}>
              <VerifyCodeInput
                maskedPhone={maskedPhone}
                companyId={unauthenticatedCompany.id}
                setLoading={setLoading}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default VerifyCodePage;
