import React from "react";

import { Checkbox, MenuItem, ListItemIcon, ListItemText } from "@mui/material";

import { MultipleDateSelectOptionType } from "./SelectDates";
import { moovsBlue, white } from "globals/design-system/colors";

type SelectDateMenuOptionProps = {
  option: MultipleDateSelectOptionType;
  isAllSelected: boolean;
  handleMenuItemClick: (
    option: MultipleDateSelectOptionType | "Select All"
  ) => void;
};

function SelectDateMenuOption(props: SelectDateMenuOptionProps) {
  const { option, isAllSelected, handleMenuItemClick } = props;

  const { id, value, isSelected } = option;

  return (
    <MenuItem
      key={id}
      value={option as any}
      onClick={() => handleMenuItemClick(option)}
      style={{ backgroundColor: white }}
    >
      <ListItemIcon>
        <Checkbox
          checked={isAllSelected || isSelected}
          sx={{
            color: moovsBlue,
            "&.Mui-checked": {
              color: moovsBlue,
            },
          }}
        />
      </ListItemIcon>
      <ListItemText primary={value.displayDateTime} />
    </MenuItem>
  );
}

export default SelectDateMenuOption;
