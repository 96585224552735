import React from "react";

const PickUpIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.757324 9.24309L9.24261 0.757812M9.24261 0.757812V9.24309M9.24261 0.757812H0.757324"
        stroke="#1E1E1E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PickUpIcon;
