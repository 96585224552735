/**
 * @file useAuth.tsx
 * Abstracts wrapping AuthContext with useContext.
 * Main point is to prevent importing two functions
 * when you want access to AuthContext.
 *
 */
import { useContext } from "react";
import Context from "./Context";
import { HandleLoginParams } from "./Provider";

export type AuthContext = {
  authStage: "pending" | "authenticated" | "rejected";
  onLogin?: (params: HandleLoginParams) => Promise<Error | undefined>;
  onLogout?: () => Promise<void>;
};

// see useAuth/Provider.tsx for implementation
function useAuth(): AuthContext {
  return useContext<AuthContext>(Context);
}

export default useAuth;
