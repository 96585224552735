/**
 * @file Router.tsx
 * Main router for site. Right now just switches between operator app and driver app.
 *
 * reactComponents:
 * Router
 * ShuttleRouter
 */

import React, { lazy, Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch, useLocation } from "react-router-dom";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";

import MoovsApolloProvider from "apollo-provider/MoovsApolloProvider";
import { useCompany } from "globals/hooks";
import { AuthProvider } from "globals/hooks/useAuth/Provider";
import PrivateRoute from "routes/PrivateRoute";
import {
  TripsPage,
  HomePage,
  BookingsPage,
  BoardingPassPage,
} from "pages/authorized";
import {
  LoadingPage,
  FourZeroFourPage,
  SignUpPage,
  LoginPage,
  VerifyCodePage,
} from "pages";

const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  uuid: "dummy", // will be replaced with actual uuid below
});

// lazy loaded pages (for code-splitting)
const Sandbox = lazy(() => import("pages/SandboxPage/SandboxPage"));

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

function Router() {
  // hooks
  const location = useLocation();

  // fixes page transition retaining scroll amount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Suspense fallback={<LoadingPage />}>
      <MoovsApolloProvider>
        <Switch location={location}>
          <Route path="/:companyId/*" children={<ShuttleRouter />} />
          <Route path="/404" children={<FourZeroFourPage />} />
        </Switch>
      </MoovsApolloProvider>
    </Suspense>
  );
}

function ShuttleRouter() {
  const { name, operator } = useCompany();

  useEffect(() => {
    if (operator?.id) pubnub.setUUID(operator.id);
  }, [operator?.id]);

  return (
    <PubNubProvider client={pubnub}>
      <AuthProvider>
        <Helmet>
          <meta property="og:title" content={name} />
          {/* <meta property="og:image" content={companyLogoUrl} /> */}
        </Helmet>

        <Switch>
          {/* developer sandbox */}
          {isDev && <Route path="/:companyId/sandbox" children={<Sandbox />} />}
          {/* unauthenticated routes */}
          {/* login / signup pages */}
          <Route path="/:companyId/sign-up" children={<SignUpPage />} />
          <Route path="/:companyId/login" children={<LoginPage />} />
          <Route path="/:companyId/verify-code" children={<VerifyCodePage />} />
          {/* authenticated routes */}
          <PrivateRoute path="/:companyId/home">
            <HomePage />
          </PrivateRoute>
          <PrivateRoute path="/:companyId/trips">
            <TripsPage />
          </PrivateRoute>
          <PrivateRoute path="/:companyId/bookings">
            <BookingsPage />
          </PrivateRoute>
          <PrivateRoute path="/:companyId/boarding-pass">
            <BoardingPassPage />
          </PrivateRoute>
          {/* 404 */}
          <Route path="/:companyId/*" children={<FourZeroFourPage />} />
        </Switch>
      </AuthProvider>
    </PubNubProvider>
  );
}

export default Router;
