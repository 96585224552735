import React, { useRef } from "react";

import { useInitializeMap } from "pages/authorized/HomePage/components/Map/hooks/useInitializeMap";
import { ShuttleStopLocation } from "types";
import { useAddStopsToMarkerMap } from "./hooks";
import "./index.css";

type MarkerMapProps = { shuttleStopLocations: ShuttleStopLocation[] };

// map that only show locations markers, it does not connect locations
function MarkerMap(props: MarkerMapProps) {
  const { shuttleStopLocations } = props;

  const googleMapRef = useRef();
  const { map } = useInitializeMap({ googleMapRef });
  useAddStopsToMarkerMap({ map, stops: shuttleStopLocations });

  return (
    <div
      style={{ width: "100%", height: "100%", borderRadius: "4px" }}
      id="map3"
      ref={googleMapRef}
    />
  );
}

export default MarkerMap;
