import React from "react";

import { Box, Typography } from "@mui/material";

import { granite } from "globals/design-system/colors";
import { NoTripsIcon } from "globals/design-system/icons";

function NoBookings() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      my="auto"
    >
      <NoTripsIcon />
      <Typography pt="15px" variant="h3">
        No Upcoming Bookings
      </Typography>
      <Typography pt="15px" variant="body2" color={granite}>
        You currently have no booked trips.
      </Typography>
    </Box>
  );
}

export default NoBookings;
