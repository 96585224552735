import React, { useEffect, useMemo, useState } from "react";
import size from "lodash/size";
import reverse from "lodash/reverse";
import last from "lodash/last";
import first from "lodash/first";

import { Box, Typography } from "@mui/material";

import { TRIPS_PAGE_SCREEN_WIDTH } from "../../TripsPage";
import { useScreenSize } from "globals/hooks";
import { BookingRouteForm, RouteMap } from "./components";
import { CompanyShuttleRoute, ShuttleStopLocation, Stop } from "types";
import MarkerMap from "./components/MarkerMap";
import { Screen, Location } from "../../TripsPage";
import NoShuttleRoutesBlock from "./components/NoShuttleRoutesBlock";
import { getStreetName } from "./components/utils";
import { white } from "globals/design-system/colors";
import { LogoHeader } from "components/layout/components";

type BookingRouteScreenProps = {
  pickUpStops: Stop[];
  returnStops: Stop[];
  location: Location;
  setPickUpStops: React.Dispatch<React.SetStateAction<Stop[]>>;
  setReturnStops: React.Dispatch<React.SetStateAction<Stop[]>>;
  setLocation: React.Dispatch<React.SetStateAction<Location>>;
  setCurrentScreen: React.Dispatch<React.SetStateAction<Screen>>;
  companyShuttleRoutes: CompanyShuttleRoute[];
};

function BookingRouteScreen(props: BookingRouteScreenProps) {
  const {
    pickUpStops,
    returnStops,
    location,
    setPickUpStops,
    setReturnStops,
    setLocation,
    setCurrentScreen,
    companyShuttleRoutes,
  } = props;

  // state
  const [selectedCompanyShuttleRoute, setSelectedCompanyShuttleRoute] =
    useState<CompanyShuttleRoute>(null);
  const [selectedPickupLocation, setSelectedPickupLocation] =
    useState<ShuttleStopLocation>(null);

  // hooks
  const { isMobileView } = useScreenSize();

  // memoize
  const shuttleRouteStopsForMap = useMemo(() => {
    const stops = selectedCompanyShuttleRoute?.shuttleRoute
      ?.shuttleRouteStops as Stop[];
    if (!stops) return null;

    // stops will either be shuttle pick up [stop 1, stop 2, office] or shuttle return [office, stop 2, stop 1]
    // if shuttle return, last stop office has no dateTime, thus reverse list so route on map will be displayed as pick up route
    return last(stops)?.dateTime ? stops : reverse([...stops]);
  }, [selectedCompanyShuttleRoute?.shuttleRoute?.shuttleRouteStops]);

  // effects
  // default first route for dropdown
  useEffect(() => {
    if (size(companyShuttleRoutes)) {
      setSelectedCompanyShuttleRoute(first(companyShuttleRoutes));
    }
    // eslint-disable-next-line
  }, []);

  // reset pick up location once a new route is selected
  useEffect(() => {
    if (selectedCompanyShuttleRoute) {
      setSelectedPickupLocation(null);
      setLocation({
        selectedLocation: null,
        officeLocation: null,
        selectedLocationAlias: null,
        officeLocationAlias: null,
      });
    }
  }, [selectedCompanyShuttleRoute, setSelectedPickupLocation, setLocation]);

  // set location for ConfirmSeatScreen
  useEffect(() => {
    if (selectedPickupLocation) {
      setLocation({
        selectedLocation: selectedPickupLocation.location,
        officeLocation: selectedPickupLocation.shuttlingLocation,
        selectedLocationAlias: selectedPickupLocation.locationAlias,
        officeLocationAlias: selectedPickupLocation.shuttlingLocationAlias,
      });
    }
  }, [selectedPickupLocation, setLocation]);

  return (
    <>
      {/* Logo Header */}
      <LogoHeader headerHeight={"90px"} />
      {/* Screen Body */}
      <Box display="flex" justifyContent="center">
        <Box
          width="100vw"
          maxWidth={TRIPS_PAGE_SCREEN_WIDTH}
          m={isMobileView ? 3 : 2}
        >
          {!size(companyShuttleRoutes) ? (
            <NoShuttleRoutesBlock />
          ) : (
            <>
              <Box position="sticky" top={0} zIndex={100} bgcolor={white}>
                {/* Map to show selected Route */}
                {shuttleRouteStopsForMap && !selectedPickupLocation ? (
                  <>
                    <Typography variant="h3">
                      {`${selectedCompanyShuttleRoute.shuttleRoute?.name} Route Overview` ||
                        ""}
                    </Typography>
                    <Box width="100%" height="250px" marginTop="15px">
                      <RouteMap shuttleRouteStops={shuttleRouteStopsForMap} />
                    </Box>
                  </>
                ) : null}
                {/* Map to show selected Pick Up Location */}
                {selectedPickupLocation ? (
                  <>
                    <Typography variant="h3">
                      {`${getStreetName({
                        stopLocation: selectedPickupLocation.location,
                        stoplocationAlias: selectedPickupLocation.locationAlias,
                      })}` || ""}
                    </Typography>
                    <Typography variant="h3">Location Overview</Typography>
                    <Box width="100%" height="250px" marginTop="15px">
                      <MarkerMap
                        shuttleStopLocations={[selectedPickupLocation]}
                      />
                    </Box>
                  </>
                ) : null}
              </Box>
              {/* Form */}
              <Box>
                <BookingRouteForm
                  pickUpStops={pickUpStops}
                  returnStops={returnStops}
                  location={location}
                  setPickUpStops={setPickUpStops}
                  setReturnStops={setReturnStops}
                  companyShuttleRoutes={companyShuttleRoutes}
                  setCurrentScreen={setCurrentScreen}
                  selectedCompanyShuttleRoute={selectedCompanyShuttleRoute}
                  setSelectedCompanyShuttleRoute={
                    setSelectedCompanyShuttleRoute
                  }
                  selectedPickupLocation={selectedPickupLocation}
                  setSelectedPickupLocation={setSelectedPickupLocation}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default BookingRouteScreen;
