import React from "react";
import { Box } from "@mui/material";

import { alabaster, grayLight } from "globals/design-system/colors";
import { CompanyLogo } from "components/feature";
import { useScreenSize } from "globals/hooks";

//constants
const DEFAULT_HEADER_HEIGHT_PIXELS = "128px";

type LogoHeaderProps = {
  headerHeight?: string;
};

function LogoHeader(props: LogoHeaderProps) {
  const { headerHeight } = props;

  //hooks
  const isMobileView = useScreenSize();

  return (
    <Box
      display="flex"
      height={headerHeight || DEFAULT_HEADER_HEIGHT_PIXELS}
      alignItems="center"
      justifyContent="center"
      bgcolor={alabaster}
      borderBottom={"1px solid " + grayLight}
    >
      <CompanyLogo size={isMobileView ? "small" : "medium"} />
    </Box>
  );
}

export default LogoHeader;
