import React from "react";

import { Box, Typography } from "@mui/material";

import GoogleMapsLocationButton from "components/ui/buttons/GoogleMapsLocationButton";

type BookingItemLocationProps = {
  bookingLocationText: string;
  bookingLocation: string;
};

function BookingItemLocation(props: BookingItemLocationProps) {
  const { bookingLocationText, bookingLocation } = props;

  return (
    <Box>
      <Typography variant="overline">Location</Typography>
      <Box mb={2} mt={0.5}>
        <GoogleMapsLocationButton
          location={bookingLocation}
          locationText={bookingLocationText}
        />
      </Box>
    </Box>
  );
}

export default BookingItemLocation;
