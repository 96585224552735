import React from "react";

import { Box, Chip } from "@mui/material";

import { grayLight, black } from "globals/design-system/colors";
import CrossIcon from "globals/design-system/icons/CrossIcon";
import { MultipleDateSelectOptionType } from "./SelectDates";

type SelectedDateChipProps = {
  text: string;
  option: MultipleDateSelectOptionType;
  handleDeleteOption: (option: MultipleDateSelectOptionType) => void;
};

function SelectedDateChip(props: SelectedDateChipProps) {
  const { text, option, handleDeleteOption } = props;

  return (
    <Box key={text}>
      <Chip
        id={text}
        label={text}
        sx={{
          backgroundColor: grayLight,
          color: black,
        }}
        onDelete={() => handleDeleteOption(option)}
        deleteIcon={
          <Box
            pr={1}
            // prevents the deleteIcon click from bubbling up to the Select component
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
          >
            <CrossIcon color={black} size="small" />
          </Box>
        }
      />
    </Box>
  );
}

export default SelectedDateChip;
