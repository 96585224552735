import { useQuery } from "@apollo/client";

import { LOAD_DRIVER_TRACKING_QUERY } from "globals/graphql";
import { Trip } from "types";

const useLoadDriverTrackingData = () => {
  const { data: driverTrackingData, loading } = useQuery(
    LOAD_DRIVER_TRACKING_QUERY,
    {
      fetchPolicy: "cache-and-network",
      pollInterval: 10 * 1000,
    }
  );

  return {
    driverTrackingData: driverTrackingData?.loadUpcomingCompanyPassengerTicket
      .trip as Trip,
    loading,
  };
};

export { useLoadDriverTrackingData };
