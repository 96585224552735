import { useCallback, useEffect, useMemo, useState } from "react";

import wayPointMarker from "globals/design-system/illustrations/driverTracking/wayPointMarker.svg";
import pickUpMarkerSVG from "globals/design-system/illustrations/driverTracking/pickUpMarker.svg";
import dropOffMarkerSVG from "globals/design-system/illustrations/driverTracking/dropOffMarker.svg";
import { createTripStopMarker } from "../utils/createTripStopMarker";
import { Stop, Trip } from "types";
import first from "lodash/first";
import last from "lodash/last";
import { moovsBlue } from "globals/design-system/colors";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { useSnackbar } from "globals/hooks";

type UseAddStopsToMapProps = {
  map: google.maps.Map;
  trip: Trip;
};

function useAddStopsToMap(props: UseAddStopsToMapProps) {
  const { map, trip } = props;

  // hooks
  const snackbar = useSnackbar();
  // state
  const [tripStopMarkers, setTripStopMarkers] = useState<
    Map<string, google.maps.Marker>
  >(new Map());

  // memoize
  const { directionsService, directionsRenderer } = useMemo(() => {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      polylineOptions: { strokeColor: moovsBlue },
    });
    directionsRenderer.setMap(map);
    return { directionsService, directionsRenderer };
  }, [map]);

  const tripStops = useMemo(() => {
    const stopsMap = new Map();
    trip.stops.forEach((stop) => {
      stopsMap.set(stop.id, stop);
    });
    return stopsMap;
  }, [trip]);

  // helper functions
  const displayTripRoute = useCallback(
    async (routeData) => {
      const { pickUpStop, dropOffStop, wayPoints } = routeData;
      try {
        // create route path between all stops
        const directionsResult = await directionsService.route({
          origin: `${pickUpStop.coordinates.x},${pickUpStop.coordinates.y}`,
          destination: `${dropOffStop.coordinates.x},${dropOffStop.coordinates.y}`,
          waypoints: wayPoints,
          travelMode: google.maps.TravelMode.DRIVING,
        });
        directionsRenderer.setDirections(directionsResult);
      } catch (error) {
        const errorMessage =
          getErrorMessage(error) || "Error displaying route.";
        snackbar.error(errorMessage);
      }
    },
    [directionsRenderer, directionsService, snackbar]
  );

  const checkSameTripStops = useCallback(
    (prevTripStopsMap, currTripStopsMap) => {
      if (prevTripStopsMap.size !== currTripStopsMap.size) return false;

      let isSame = true;
      currTripStopsMap.forEach(
        ({ stopIndex: currStopIndex, coordinates: currCoords }, currStopId) => {
          if (!prevTripStopsMap.has(currStopId)) {
            isSame = false;
          } else {
            if (
              !hasSameCoordsandStopIndex(
                prevTripStopsMap,
                currStopId,
                currCoords,
                currStopIndex
              )
            ) {
              isSame = false;
            }
          }
        }
      );

      return isSame;
    },
    []
  );

  const hasSameCoordsandStopIndex = (
    prevTripStopsMap,
    currStopId,
    currCoords,
    currStopIndex
  ) => {
    const prevTripStop = prevTripStopsMap.get(currStopId);
    const prevLat = prevTripStop.getPosition().lat();
    const prevLng = prevTripStop.getPosition().lng();
    return (
      prevLat === currCoords?.x &&
      prevLng === currCoords?.y &&
      prevTripStop["stopIndex"] === currStopIndex
    );
  };

  const createTripMarkersAndWayPoints = async (trip, map) => {
    const tripStopMarkersMap = new Map();

    // pick-up
    const pickUpStop: Stop = first(trip.stops);
    tripStopMarkersMap.set(
      pickUpStop.id,
      await createTripStopMarker(pickUpStop, map, pickUpMarkerSVG, null)
    );

    // drop off
    const dropOffStop: Stop = last(trip.stops);
    tripStopMarkersMap.set(
      dropOffStop.id,
      await createTripStopMarker(dropOffStop, map, dropOffMarkerSVG, null)
    );

    // mid stops
    const wayPoints = [];
    if (trip.stops.length > 2) {
      const midStops = trip.stops.slice(1, trip.stops.length - 1);
      midStops.forEach(async (midStop) => {
        wayPoints.push({
          location: `${midStop.coordinates?.x},${midStop.coordinates?.y}`,
          stopover: true,
        });
        tripStopMarkersMap.set(
          midStop.id,
          await createTripStopMarker(
            midStop,
            map,
            wayPointMarker,
            midStop.stopIndex - 1
          )
        );
      });
    }

    return {
      tripStopMarkersMap,
      wayPoints,
      dropOffStop,
      pickUpStop,
    };
  };

  // create new markers if there is a change in trip stops
  useEffect(() => {
    const verify = async () => {
      // if there is a change in trip stops
      if (map && !checkSameTripStops(tripStopMarkers, tripStops)) {
        // remove all markers
        tripStopMarkers.forEach((tripStopMarker) => {
          tripStopMarker.setMap(null);
        });

        // create new markers
        const { wayPoints, tripStopMarkersMap, pickUpStop, dropOffStop } =
          await createTripMarkersAndWayPoints(trip, map);

        setTripStopMarkers(tripStopMarkersMap);

        // add route path to map
        displayTripRoute({
          wayPoints,
          pickUpStop,
          dropOffStop,
        });
      }
    };
    verify();
  }, [
    map,
    trip,
    directionsRenderer,
    directionsService,
    tripStopMarkers,
    tripStops,
    displayTripRoute,
    checkSameTripStops,
  ]);
}

export default useAddStopsToMap;
