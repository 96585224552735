import { useQuery } from "@apollo/client";
import moment from "moment";

import { LOAD_COMPANY_SHUTTLE_ROUTES_QUERY } from "globals/graphql";
import { CompanyShuttleRoute } from "types";

const useLoadCompanyShuttleRoutes = () => {
  const {
    data: companyShuttleRoutesData,
    loading,
    error,
    refetch: refetchCompanyShuttleRoutes,
  } = useQuery<{ loadCompanyShuttleRoutes: CompanyShuttleRoute[] }>(
    LOAD_COMPANY_SHUTTLE_ROUTES_QUERY,
    {
      variables: {
        utcOffsetMinutes: moment().utcOffset(),
      },
      fetchPolicy: "cache-and-network",
    }
  );

  return {
    companyShuttleRoutes: companyShuttleRoutesData?.loadCompanyShuttleRoutes,
    loading,
    error,
    refetchCompanyShuttleRoutes,
  };
};

export { useLoadCompanyShuttleRoutes };
