import React from "react";

import { Box, Typography } from "@mui/material";

import { Greeting } from "./components";
import { SignUpForm } from "./components/SignUpForm";
import { RedirectPageOption } from "components/feature";
import { useLoadCompanyForUnauthenticated } from "globals/hooks";
import { LogoHeader } from "components/layout/components";
import { granite } from "globals/design-system/colors";

// constants
const SIGN_UP_PAGE_WIDTH = "450px";

function SignUpPage() {
  // hooks
  const { unauthenticatedCompany } = useLoadCompanyForUnauthenticated();

  return (
    <>
      {/* page header */}
      <LogoHeader />
      {/* Page Body */}
      <Box display="flex" justifyContent="center">
        <Box padding={2} maxWidth={SIGN_UP_PAGE_WIDTH}>
          <Box my={3}>
            <Greeting />
          </Box>
          <Box mt={4} mb={3}>
            <SignUpForm />
          </Box>
          <Typography variant="body2" color={granite} textAlign="center" mt={1}>
            By clicking “Create Account” you agree to receive trip updates via
            SMS. Messages & data rates may apply. You can also opt out of
            receiving text messages at any time by replying STOP.
          </Typography>
          <Box my={3}>
            <RedirectPageOption
              statement="Already have an account?"
              alternateName="Login"
              alternatePath={`/${unauthenticatedCompany?.id}/login`}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SignUpPage;
