import first from "lodash/first";
import moment from "moment";
import { RouteDispatchLogStatusEnum, Ticket, Trip, TripCategory } from "types";

const etaUtil = (ticket: Ticket, trip: Trip) => {
  // derived state
  const pickupStopIndex =
    ticket.trip.tripCategory === TripCategory.ShuttlePickUp
      ? ticket.stop.stopIndex
      : 1; // return trips always pickup from first stop

  const { currentStop, currentStatus, currentStopArrivalTime, driverEta } =
    first(trip.routes).routeDispatch;

  if (
    ticket.trip.tripCategory === TripCategory.ShuttleReturn &&
    currentStop.stopIndex > ticket.stop.stopIndex
  ) {
    //driver is on return trip and has passed ticket drop off stop
    const stop = trip.stops.filter(
      (stop) => stop.stopIndex === ticket.stop.stopIndex
    );

    return moment(first(stop).stopDispatch.arrivedAt).format("h:mm A");
  }

  if (pickupStopIndex === currentStop.stopIndex) {
    if (currentStatus === RouteDispatchLogStatusEnum.Arrived) {
      //driver has arrived at pick up stop
      return moment(currentStopArrivalTime).format("h:mm A");
    }

    //driver is heading to pick up stop
    return `ETA: ${moment(driverEta).format("h:mm A")}`;
  }

  //all other cases
  return "";
};

export default etaUtil;
