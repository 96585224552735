/**
 * @file graphql/companySchedule.ts
 * queries/mutations for companySchedule.
 *
 * companySchedule contains shuttle trip info for the following 7 days
 */

import { gql } from "@apollo/client";

// deprecated on 02/26/2024... Use loadCompanyShuttleRoutes instead
export const LOAD_COMPANY_SCHEDULES_QUERY = gql`
  query LoadCompanySchedules($utcOffsetMinutes: Int!) {
    loadCompanySchedules(utcOffsetMinutes: $utcOffsetMinutes) {
      id
      date
      tickets {
        id
        ticketHolder {
          id
          firstName
          lastName
          mobilePhone
          company {
            id
            name
          }
        }
        ticketNumber
        stop {
          id
          stopIndex
          location
          dateTime
          pickUpDateTime
        }
        trip {
          id
          tripCategory
        }
      }
      shuttleStopLocations {
        id
        location
        shuttlingLocation
        pickUpStops {
          id
          stopIndex
          dateTime
          location
          trip {
            availableSeats
          }
        }
        returnStops {
          id
          stopIndex
          dateTime
          location
          pickUpDateTime
          pickUpTimezoneId
          trip {
            availableSeats
          }
        }
      }
    }
  }
`;
