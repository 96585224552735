type GetStreetNameParams = {
  stopLocation: string;
  stoplocationAlias?: string;
  limit?: number;
};

export const getStreetName = (params: GetStreetNameParams) => {
  const { stopLocation, stoplocationAlias, limit = 35 } = params;

  const location = stoplocationAlias || stopLocation.split(",")[0];

  return location.length > limit
    ? location.substring(0, limit) + ".."
    : location;
};
