/**
 * @file useCompany
 * Hook that abstracts company query.
 */

import { useQuery } from "@apollo/client";

import { useSnackbar } from "./useSnackbar";
import { useCompanyId } from "./useCompanyId";
import { LOAD_COMPANY_QUERY } from "globals/graphql";
import { Company } from "types";

function useCompany(): Company {
  // hooks
  const companyId = useCompanyId();
  const snackbar = useSnackbar();

  // queries
  const { data, error } = useQuery(LOAD_COMPANY_QUERY, {
    variables: {
      id: companyId,
    },
    skip: !companyId,
  });

  if (error) {
    snackbar.error(error.message);
  }

  return data?.node || {};
}

export { useCompany };
