import React, { useMemo } from "react";
import orderBy from "lodash/orderBy";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { getStreetName } from "../../utils";
import { CompanyShuttleRoute, ShuttleStopLocation } from "types";

type SelectLocationProps = {
  selectedCompanyShuttleRoute: CompanyShuttleRoute;
  setSelectedPickupLocation: React.Dispatch<
    React.SetStateAction<ShuttleStopLocation>
  >;
  selectedPickupLocation: ShuttleStopLocation;
};

function SelectLocation(props: SelectLocationProps) {
  const {
    selectedCompanyShuttleRoute,
    setSelectedPickupLocation,
    selectedPickupLocation,
  } = props;

  const handleSelectLocationChange = (e) => {
    const { value } = e.target;
    setSelectedPickupLocation(value);
  };

  const stopLocationsSortedByName = useMemo(
    () =>
      orderBy(selectedCompanyShuttleRoute?.shuttleStopLocations, (stop) => {
        const name = stop ? stop.locationAlias || stop.location : "Other";
        return name.toLowerCase();
      }),
    [selectedCompanyShuttleRoute?.shuttleStopLocations]
  );

  return (
    <Box mt={2}>
      <Typography variant="h4" mb={1}>
        Location
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="select-location-label">Select Location</InputLabel>
        <Select
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 140,
                overflow: "scroll",
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }}
          labelId="select-location-label"
          id="select-location"
          value={selectedPickupLocation === null ? "" : selectedPickupLocation}
          label="Select Location"
          onChange={handleSelectLocationChange}
        >
          {stopLocationsSortedByName.map((stop) => {
            return (
              //@ts-ignore - necessary to load object into value
              <MenuItem value={stop} key={stop.id}>
                {getStreetName({
                  stopLocation: stop.location,
                  stoplocationAlias: stop.locationAlias,
                })}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectLocation;
