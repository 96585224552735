/**
 * @file trip.graphql.ts
 * queries/mutations for trips.
 */

import { gql } from "@apollo/client";

export const CANCEL_COMPANY_PASSENGER_TICKET_MUTATION = gql`
  mutation CancelCompanyPassengerTicket(
    $input: CancelCompanyPassengerTicketInput!
  ) {
    cancelCompanyPassengerTicket(input: $input) {
      ticket {
        id
      }
    }
  }
`;
