import React from "react";

import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import { useScreenSize } from "globals/hooks";
import {
  alabaster,
  granite,
  grayMedium,
  moovsBlue,
  moovsBlueSelected,
} from "globals/design-system/colors";

type MoovsToggleButtonGroupProps = {
  options: // one of icon or label is required
  (
    | {
        ariaLabel?: string;
        value: string;
        icon: any;
        dataTestId?: string;
        label?: string;
      }
    | {
        ariaLabel?: string;
        value: string;
        icon?: any;
        dataTestId?: string;
        label: string;
      }
  )[];
  value: any;
  onChange: (variant: any) => void;
  sx?: Record<string, unknown>;
  hideIconsOnMobile?: boolean;
  size?: "small" | "large";
  fullWidth?: boolean;
  wrapLabel?: boolean;
  disabledGroup?: boolean;
};

const styles = {
  padding: "12px",
  "&.MuiToggleButton-root": {
    border: `${grayMedium} 1px solid !important`,
    ":hover": {
      backgroundColor: alabaster,
    },
    ":focus": {
      backgroundColor: moovsBlueSelected,
      color: moovsBlue,
    },
    "&.Mui-selected": {
      backgroundColor: moovsBlueSelected,
    },
  },
};

function MoovsToggleButtonGroup(props: MoovsToggleButtonGroupProps) {
  const {
    options,
    value,
    onChange,
    sx,
    hideIconsOnMobile,
    size = "small",
    fullWidth,
    wrapLabel,
    disabledGroup,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // derived state
  const shouldHideIcons = isMobileView && hideIconsOnMobile;
  const fullWidthOnMobile = isMobileView && fullWidth;
  const shouldWrapLabel = isMobileView && wrapLabel;

  // event handler
  const handleToggle = (e: React.MouseEvent<HTMLElement>, variant: unknown) =>
    onChange(variant);

  return (
    <ToggleButtonGroup
      exclusive
      value={value}
      onChange={handleToggle}
      fullWidth={fullWidthOnMobile}
      {...(disabledGroup && {
        disabled: disabledGroup,
      })}
    >
      {options.map((toggleButton, i) => {
        const { icon, label, ariaLabel = "", dataTestId = "" } = toggleButton;

        const Icon = icon;

        return (
          <ToggleButton
            sx={[styles, sx]}
            key={i}
            disableRipple
            value={toggleButton.value}
            aria-label={ariaLabel}
            data-testid={dataTestId}
          >
            <Box
              display="flex"
              flexDirection={isMobileView ? "column" : "row"}
              justifyContent="center"
              alignItems="center"
            >
              {/* icon */}
              {icon && !shouldHideIcons && (
                <Icon
                  color={toggleButton.value === value ? moovsBlue : granite}
                  size={size}
                />
              )}

              {/* spacing, only needed if both icon and label */}
              {icon && !shouldHideIcons && label && <Box width="8px" />}

              {/* label */}
              {label && (
                <Typography
                  noWrap={!shouldWrapLabel}
                  variant="button"
                  lineHeight="1.28"
                  color={toggleButton.value === value ? moovsBlue : granite}
                  mt={(fullWidthOnMobile || shouldWrapLabel) && 1}
                >
                  {label}
                </Typography>
              )}
            </Box>
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

export default MoovsToggleButtonGroup;
