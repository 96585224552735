import React, { ReactNode } from "react";
import { Box } from "@mui/material";

import { alabaster, grayLight } from "globals/design-system/colors";

type HeaderProps = {
  heightPixels: string;
  children: ReactNode;
  bgColor?: string;
};

function Header(props: HeaderProps) {
  const { heightPixels, children, bgColor } = props;

  return (
    <Box
      display="flex"
      minHeight={heightPixels}
      alignItems="center"
      justifyContent="center"
      borderBottom={"1px solid " + grayLight}
      bgcolor={bgColor || alabaster}
    >
      {children}
    </Box>
  );
}

export default Header;
