import moment from "moment";
import React from "react";

import { Typography, Box } from "@mui/material";

import { alabaster, black, grayDark } from "globals/design-system/colors";
import { ArrowReturnIcon, ArrowUpHalfIcon } from "globals/design-system/icons";
import { ShuttleTypes, Location } from "pages/authorized/TripsPage/TripsPage";
import { Stop } from "types";
import GoogleMapsLocationButton from "components/ui/buttons/GoogleMapsLocationButton";

type ConfirmShuttleTripProps = {
  stops: Stop[];
  shuttleType: ShuttleTypes;
  location: Location;
};

function ConfirmShuttleTrip(props: ConfirmShuttleTripProps) {
  const { stops, location, shuttleType } = props;

  const {
    selectedLocation,
    selectedLocationAlias,
    officeLocation,
    officeLocationAlias,
  } = location;

  const selectedLocationText = selectedLocationAlias || selectedLocation;
  const officeLocationText = officeLocationAlias || officeLocation;

  // Since the stops being confirmed have the same time (hour and minutes),
  // we can just get the time from the first stop
  const pickUpTime =
    shuttleType === ShuttleTypes.PickUpOnly
      ? moment.utc(stops[0].dateTime).format("h:mm A")
      : moment.utc(stops[0].pickUpDateTime).format("h:mm A");

  const selectedLocationButton = (
    <Box py={0.5}>
      <GoogleMapsLocationButton
        location={selectedLocation}
        locationText={selectedLocationText}
      />
    </Box>
  );

  const officeLocationButton = (
    <Box py={0.5}>
      <GoogleMapsLocationButton
        location={officeLocation}
        locationText={officeLocationText}
      />
    </Box>
  );

  return (
    <Box mb={2}>
      <Box display="flex" alignItems="center" mb={1}>
        {shuttleType === ShuttleTypes.PickUpOnly ? (
          <ArrowUpHalfIcon color={grayDark} />
        ) : (
          <ArrowReturnIcon color={grayDark} />
        )}
        <Typography variant="h4" ml={2}>
          {shuttleType === ShuttleTypes.PickUpOnly
            ? "Pick-Up Trip"
            : "Return Trip"}
        </Typography>
      </Box>
      <Box
        sx={{
          borderLeft:
            shuttleType === ShuttleTypes.PickUpOnly
              ? "1px solid rgba(211, 211, 211, 0.5)"
              : "none",
        }}
      >
        <Box sx={{ backgroundColor: alabaster, marginLeft: 4, padding: 2 }}>
          {/* Pick Up Time */}
          <Typography variant="overline" color={grayDark}>
            Pick-Up Time
          </Typography>
          <Typography variant="subtitle2" color={black} mb={1}>
            {pickUpTime}
          </Typography>

          {/* Pick Up Location */}
          <Typography variant="overline" color={grayDark}>
            Pick-Up Location
          </Typography>
          {shuttleType === ShuttleTypes.PickUpOnly
            ? selectedLocationButton
            : officeLocationButton}

          {/* Drop Off Location */}
          <Typography variant="overline" color={grayDark}>
            Drop-Off Location
          </Typography>
          {shuttleType === ShuttleTypes.PickUpOnly
            ? officeLocationButton
            : selectedLocationButton}
        </Box>
      </Box>
    </Box>
  );
}

export default ConfirmShuttleTrip;
