import React from "react";

type BookingsCalendarIconProps = {
  color?: string;
};

const BookingsCalendarIcon = (props: BookingsCalendarIconProps) => {
  const { color } = props;

  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 4H5.75C4.64543 4 3.75 4.89543 3.75 6V9M8.75 4H16.75M8.75 4V2M8.75 4V6M16.75 4H19.75C20.8546 4 21.75 4.89543 21.75 6V9M16.75 4V2M16.75 4V6M12.75 22H5.75C4.64543 22 3.75 21.1046 3.75 20V9M3.75 9H21.75M21.75 9V11.5M17.25 22V13M17.25 13L21.75 17.5M17.25 13L12.75 17.5"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BookingsCalendarIcon;
