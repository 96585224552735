import React, { useMemo, useState, useEffect } from "react";
import size from "lodash/size";
import sortBy from "lodash/sortBy";
import every from "lodash/every";
import moment from "moment";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";

import { ShuttleStopLocation, Stop } from "types";
import { grayLight, moovsBlue, granite } from "globals/design-system/colors";
import SelectedDateChip from "./SelectedDateChip";
import SelectDateMenuOption from "./SelectDateMenuOption";

export type SelectedDateData = {
  pickUpTime: Stop[];
  returnTime: Stop[];
  displayDateTime: string;
};

export type MultipleDateSelectOptionType = {
  id: string;
  value: SelectedDateData;
  isSelected: boolean;
};

type SelectDatesProps = {
  selectedPickupLocation: ShuttleStopLocation;
  setSelectedDates: React.Dispatch<React.SetStateAction<SelectedDateData[]>>;
};

function SelectDates(props: SelectDatesProps) {
  const { selectedPickupLocation, setSelectedDates } = props;

  // state
  const [menuOptions, setMenuOptions] = useState<
    MultipleDateSelectOptionType[]
  >([]);

  const selectAllValue = "Select All";
  const label = "Select Pick-up Dates";

  // memoize
  const dateTimeMap = useMemo(() => {
    const dateTimeMap: Map<string, SelectedDateData> = new Map();

    selectedPickupLocation?.pickUpStops.forEach((stop) => {
      const date = moment.utc(stop.dateTime).format("MMM DD, YYYY");
      const weekday = moment.utc(stop.dateTime).format("dddd");

      if (!dateTimeMap.has(date)) {
        dateTimeMap.set(date, {
          displayDateTime: `${weekday}, ${date}`,
          pickUpTime: [stop],
          returnTime: [],
        });
      } else {
        dateTimeMap.get(date).pickUpTime.push(stop);
      }
    });

    selectedPickupLocation?.returnStops.forEach((stop) => {
      const date = moment.utc(stop.pickUpDateTime).format("MMM DD, YYYY");
      const weekday = moment.utc(stop.pickUpDateTime).format("dddd");

      if (!dateTimeMap.has(date)) {
        dateTimeMap.set(date, {
          displayDateTime: `${weekday}, ${date}`,
          returnTime: [stop],
          pickUpTime: [],
        });
      } else {
        dateTimeMap.get(date).returnTime.push(stop);
      }
    });

    return dateTimeMap;
  }, [selectedPickupLocation]);

  const dateOptions = useMemo(() => {
    const dates = [];
    for (const [dateKey, dateTimeData] of dateTimeMap) {
      dates.push({ dateKey, dateTimeData });
    }
    return sortBy(dates, (date) => new Date(date.dateKey).getTime());
  }, [dateTimeMap]);

  const initialMenuOptions = useMemo(
    () =>
      dateOptions.map((date) => ({
        id: date.dateKey,
        value: date.dateTimeData,
        isSelected: false,
      })),
    [dateOptions]
  );

  // event handler
  const updateStateValues = (
    newMenuOptions: MultipleDateSelectOptionType[]
  ) => {
    setMenuOptions(newMenuOptions);

    const selectedValues = newMenuOptions
      .filter((item) => item.isSelected)
      .map((option) => option.value);

    setSelectedDates(selectedValues);
  };

  const handleMenuItemClick = (
    option: MultipleDateSelectOptionType | "Select All"
  ) => {
    let newMenuOptions = [...menuOptions];

    if (option === selectAllValue) {
      // select all or unselect all
      if (menuOptions.some((item) => !item.isSelected)) {
        newMenuOptions.forEach((item) => (item.isSelected = true));
      } else {
        newMenuOptions.forEach((item) => (item.isSelected = false));
      }
    } else {
      if (option.isSelected) {
        // unselecting an individual item
        newMenuOptions.find((item) => item.id === option.id).isSelected = false;
      } else {
        // selecting an individual item
        newMenuOptions.find((item) => item.id === option.id).isSelected = true;
      }
    }

    updateStateValues(newMenuOptions);
  };

  const handleDeleteOption = (option: MultipleDateSelectOptionType) => {
    let newMenuOptions = [...menuOptions];

    newMenuOptions.find((item) => item.id === option.id).isSelected = false;

    updateStateValues(newMenuOptions);
  };

  const isAllSelected = useMemo(() => {
    return every(menuOptions, (option) => option.isSelected);
  }, [menuOptions]);

  useEffect(() => {
    setMenuOptions(initialMenuOptions);
  }, [initialMenuOptions]);

  return (
    <Box mt={2}>
      <Typography variant="h4" mb={1}>
        Pick-up Date
      </Typography>
      {!size(menuOptions) ? (
        <FormControl fullWidth disabled>
          <InputLabel id="no-route-label">{label}</InputLabel>
          <Select labelId="no-route-label" id="no-route" label="None" value="">
            <MenuItem value="">
              <em>{label}</em>
            </MenuItem>
          </Select>
        </FormControl>
      ) : (
        <FormControl fullWidth>
          <InputLabel id="select-date-label">{label}</InputLabel>
          <Select
            multiple
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 250,
                  overflow: "scroll",
                },
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            }}
            labelId="select-date-label"
            id="select-date"
            value={menuOptions}
            label={label}
            renderValue={() => (
              <Box display="flex" flexDirection="column" gap={1}>
                {menuOptions.every((item) => !item.isSelected) ? (
                  <Typography color={granite}>{label}</Typography>
                ) : (
                  menuOptions
                    .filter((option) => option.isSelected)
                    .map((option) => {
                      const text = option.value?.displayDateTime || "";
                      return (
                        <SelectedDateChip
                          key={option.id}
                          text={text}
                          option={option}
                          handleDeleteOption={handleDeleteOption}
                        />
                      );
                    })
                )}
              </Box>
            )}
          >
            {/* Select All option */}
            <MenuItem
              value={selectAllValue}
              onClick={() => handleMenuItemClick(selectAllValue)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={isAllSelected}
                  sx={{
                    color: moovsBlue,
                    "&.Mui-checked": {
                      color: moovsBlue,
                    },
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={selectAllValue} />
            </MenuItem>
            <Box px={1}>
              <Divider
                sx={{
                  backgroundColor: grayLight,
                }}
              />
            </Box>
            {/* other options */}
            {menuOptions.map((option) => (
              <SelectDateMenuOption
                key={option.id}
                option={option}
                isAllSelected={isAllSelected}
                handleMenuItemClick={handleMenuItemClick}
              />
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}

export default SelectDates;
