/**
 * @file graphql/operator.ts
 * queries/mutations for Operator.
 */

import { gql } from "@apollo/client";

export const LOAD_COMPANY_QUERY = gql`
  query Company($id: ID!) {
    node(id: $id) {
      id
      ... on Company {
        id
        name
        companyLogoUrl
        operator {
          id
          name
        }
      }
    }
  }
`;

export const LOAD_COMPANY_FOR_UNATHENTICATED_QUERY = gql`
  query LoadCompanyForUnauthenticated {
    loadCompanyForUnauthenticated {
      id
      name
      companyLogoUrl
    }
  }
`;
