import React from "react";

type HomeIconProps = {
  color?: string;
};

const HomeIcon = (props: HomeIconProps) => {
  const { color } = props;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4484 7.92352L9.754 1L1.0516 7.92352C0.871694 8.09874 0.763516 8.34101 0.75 8.59899V19.1717C0.750633 19.3905 0.833268 19.6002 0.979859 19.7549C1.12645 19.9096 1.32509 19.9968 1.5324 19.9975H6.75V15.5673C6.75 14.7275 7.06607 13.9222 7.62868 13.3284C8.19129 12.7346 8.95435 12.401 9.75 12.401C10.5456 12.401 11.3087 12.7346 11.8713 13.3284C12.4339 13.9222 12.75 14.7275 12.75 15.5673V20H17.9668C18.1742 19.9993 18.3729 19.9121 18.5196 19.7575C18.6663 19.6028 18.7492 19.3931 18.75 19.1742V8.59899C18.7362 8.3411 18.628 8.09895 18.4484 7.92352Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HomeIcon;
