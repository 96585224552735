import React, { useRef } from "react";

import { useInitializeMap } from "pages/authorized/HomePage/components/Map/hooks/useInitializeMap";
import { useAddStopsToRouteMap } from "./hooks";
import "./index.css";
import { Stop } from "types";

type RouteMapProps = { shuttleRouteStops: Stop[] };

// map that connects shuttle route locations
function RouteMap(props: RouteMapProps) {
  const { shuttleRouteStops } = props;

  const googleMapRef = useRef();
  const { map } = useInitializeMap({ googleMapRef });
  useAddStopsToRouteMap({ map, stops: shuttleRouteStops });

  return (
    <div
      style={{ width: "100%", height: "100%", borderRadius: "4px" }}
      id="map2"
      ref={googleMapRef}
    />
  );
}

export default RouteMap;
