import React from "react";
import { Control, Controller, FieldErrorsImpl } from "react-hook-form";
import InputMask from "react-input-mask";

import { TextField } from "@mui/material";

export enum RHFMobileFieldMaskEnum {
  Standard = "(999) 999-9999",
}

type RHFMobileFieldProps = {
  name: string;
  control: Control<any>;
  errors: FieldErrorsImpl<{
    firstName: string;
    lastName: string;
    mobile: string;
  }>;
  mask: RHFMobileFieldMaskEnum;

  // All other TextField props
  [x: string]: any;
};

function RHFMobileField(props: RHFMobileFieldProps) {
  const { name, control, errors, mask, ...textFieldProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, ...otherFields } }) => {
        const allProps = { ...otherFields, ...textFieldProps };
        return (
          <InputMask
            mask={mask}
            maskChar=" "
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          >
            {() => (
              <TextField
                {...allProps}
                fullWidth
                error={!!errors[name]}
                helperText={errors[name]?.message}
              />
            )}
          </InputMask>
        );
      }}
    />
  );
}

export default RHFMobileField;
