/**
 * @file graphql/companyShuttleRoute.ts
 *
 * companyShuttleRoute contains routes for the next 10 days
 */

import { gql } from "@apollo/client";

export const LOAD_COMPANY_SHUTTLE_ROUTES_QUERY = gql`
  query LoadCompanyShuttleRoutes($utcOffsetMinutes: Int!) {
    loadCompanyShuttleRoutes(utcOffsetMinutes: $utcOffsetMinutes) {
      id
      shuttleRoute {
        id
        name
        shuttleRouteStops {
          id
          coordinates
          location
          locationAlias
          stopIndex
          dateTime
        }
      }
      shuttleStopLocations {
        id
        location
        locationAlias
        coordinates
        shuttlingLocation
        shuttlingLocationAlias
        pickUpStops {
          id
          stopIndex
          dateTime
          location
          locationAlias
          trip {
            id
            availableSeats
          }
        }
        returnStops {
          id
          stopIndex
          dateTime
          location
          locationAlias
          pickUpDateTime
          pickUpTimezoneId
          trip {
            id
            availableSeats
          }
        }
      }
    }
  }
`;
