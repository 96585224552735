import React from "react";

type ArrowReturnIconIconProps = {
  color?: string;
  size?: "small";
};

const ArrowReturnIcon = (props: ArrowReturnIconIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.4 13.1992L1 10.7992M1 10.7992L3.4 8.39922M1 10.7992H10.6C11.2365 10.7992 11.847 10.5464 12.2971 10.0963C12.7471 9.64619 13 9.03574 13 8.39922V3.59922C13 2.9627 12.7471 2.35225 12.2971 1.90216C11.847 1.45208 11.2365 1.19922 10.6 1.19922H6.2"
          stroke="#565656"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 16.5L1 13.5M1 13.5L4 10.5M1 13.5H13C13.7956 13.5 14.5587 13.1839 15.1213 12.6213C15.6839 12.0587 16 11.2956 16 10.5V4.5C16 3.70435 15.6839 2.94129 15.1213 2.37868C14.5587 1.81607 13.7956 1.5 13 1.5H7.5"
        stroke={color || "#565656"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowReturnIcon;
