import { matchPath, useLocation } from "react-router-dom";

type ParamType = {
  companyId?: string;
};

function useCompanyId() {
  const location = useLocation();

  const match = matchPath(location.pathname, {
    path: "/:companyId",
  });

  const params = match?.params as ParamType;
  return params?.companyId || "";
}

export { useCompanyId };
