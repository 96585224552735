import React from "react";
import { Box, Typography } from "@mui/material";

import { LogoWithTextHeader } from "components/layout/components";
import { granite, white } from "globals/design-system/colors";
import { useLoadUpcomingCompanyPassengerTicket } from "globals/hooks";
import { Menu } from "../../../components/ui";
import { NoUpcomingTrip, UpcomingTrip } from "./components";
import { useCurrentCompanyPassenger } from "globals/hooks/useCurrentCompanyPassenger";

function HomePage() {
  //hooks
  const { ticketData } = useLoadUpcomingCompanyPassengerTicket();
  const passenger = useCurrentCompanyPassenger();

  return (
    <>
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        zIndex={-1}
        bgcolor={white}
      />
      <LogoWithTextHeader>
        <Box width="100%">
          <Typography sx={{ fontSize: "24px" }} align="left" color={granite}>
            Hello,
          </Typography>
          <Typography variant="h1">{passenger?.firstName}</Typography>
        </Box>
      </LogoWithTextHeader>
      {ticketData && passenger ? (
        <UpcomingTrip ticket={ticketData} passenger={passenger} />
      ) : (
        <NoUpcomingTrip />
      )}
      <Menu />
    </>
  );
}

export default HomePage;
