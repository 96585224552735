import { ApolloError, useQuery } from "@apollo/client";

import { LOAD_COMPANY_FOR_UNATHENTICATED_QUERY } from "globals/graphql";
import { UnauthenticatedCompany } from "types";

type UseLoadCompanyForUnauthenticatedData = {
  unauthenticatedCompany?: UnauthenticatedCompany;
  loading: boolean;
  error?: ApolloError;
};

const useLoadCompanyForUnauthenticated =
  (): UseLoadCompanyForUnauthenticatedData => {
    const {
      data: unauthenticatedCompany,
      loading,
      error,
    } = useQuery(LOAD_COMPANY_FOR_UNATHENTICATED_QUERY, {
      fetchPolicy: "cache-and-network",
    });

    return {
      unauthenticatedCompany:
        unauthenticatedCompany?.loadCompanyForUnauthenticated,
      loading,
      error,
    };
  };

export { useLoadCompanyForUnauthenticated };
