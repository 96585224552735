import React from "react";
import { Typography, Box } from "@mui/material";

function FourZeroFourPage() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      minHeight="90vh"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h2">404</Typography>
      <Typography variant="body1">Sorry! This page does not exist.</Typography>
    </Box>
  );
}

export default FourZeroFourPage;
