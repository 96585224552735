import React from "react";

const NoTripsIcon = () => {
  return (
    <svg
      width="141"
      height="140"
      viewBox="0 0 141 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="140" height="140" rx="70" fill="#E5EEFF" />
      <g opacity="0.5">
        <path
          d="M49.5 60.881C43.3517 66.5459 39.5 74.6655 39.5 83.6848C39.5 92.704 43.3517 100.824 49.5 106.489L49.5 60.881Z"
          fill="url(#paint0_linear_1990_346952)"
        />
        <path
          d="M101.5 83.6848C101.5 74.186 97.2278 65.685 90.5 59.9985L90.5 107.371C97.2278 101.685 101.5 93.1836 101.5 83.6848Z"
          fill="url(#paint1_linear_1990_346952)"
        />
      </g>
      <path
        opacity="0.5"
        d="M72.6141 108L86.5 108C88.7091 108 90.5 106.209 90.5 104L90.5 38.476C90.5 37.3129 89.7079 36.2992 88.5793 36.018C87.1951 35.6732 85.7865 35.4351 84.3659 35.306L81 35C80.3433 36.9701 79.2149 38.7498 77.7132 40.1841L77.4149 40.469L62.5851 40.469C61.2097 39.1702 60.1155 37.6028 59.3703 35.864L59 35L55.2064 35C53.4265 35 51.6709 35.4136 50.0782 36.2081C49.7239 36.3849 49.5 36.7468 49.5 37.1427L49.5 104C49.5 106.209 51.2909 108 53.5 108L72.6141 108Z"
        fill="url(#paint2_linear_1990_346952)"
      />
      <path
        d="M59.5 35V34.25C59.0858 34.25 58.75 34.5858 58.75 35H59.5ZM80.5 35H81.25C81.25 34.5858 80.9142 34.25 80.5 34.25V35ZM59.5 35.75H80.5V34.25H59.5V35.75ZM79.75 35V36H81.25V35H79.75ZM76.5 39.25H63.5V40.75H76.5V39.25ZM60.25 36V35H58.75V36H60.25ZM63.5 39.25C61.7051 39.25 60.25 37.7949 60.25 36H58.75C58.75 38.6234 60.8766 40.75 63.5 40.75V39.25ZM79.75 36C79.75 37.7949 78.2949 39.25 76.5 39.25V40.75C79.1234 40.75 81.25 38.6234 81.25 36H79.75Z"
        fill="#195FE9"
      />
      <path
        d="M58.5 94C58.5 87.25 58.6955 81.7913 66.1521 78.6591C71.0216 76.6137 74.1522 77.6364 74.1522 71.5C74.1522 68.0909 76.2627 65.3636 79.3932 65.3636C80.6685 65.3636 82.5 64.75 82.5 62.2955C82.5 59.8409 82.5 58.9091 82.5 58"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="82.5"
        cy="55"
        r="3"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="58.5"
        cy="92"
        r="3"
        fill="#195FE9"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="49.5"
        y="35"
        width="41"
        height="73"
        rx="4"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.5 58.5789L72.5 51L64.9211 67L63.2368 60.2632L56.5 58.5789Z"
        fill="white"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1990_346952"
          x1="70.4966"
          y1="59.9661"
          x2="70.4966"
          y2="107.372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#195FE9" stopOpacity="0.04" />
          <stop offset="1" stopColor="#87AAFF" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1990_346952"
          x1="70.4966"
          y1="59.9661"
          x2="70.4966"
          y2="107.372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#195FE9" stopOpacity="0.04" />
          <stop offset="1" stopColor="#87AAFF" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1990_346952"
          x1="69.9978"
          y1="36.4477"
          x2="69.9978"
          y2="108.002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#195FE9" />
          <stop offset="1" stopColor="#87AAFF" stopOpacity="0.62" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoTripsIcon;
