import first from "lodash/first";
import { RouteDispatchLogStatusEnum, Ticket, Trip, TripCategory } from "types";

const locationTextUtil = (ticket: Ticket, trip: Trip) => {
  // derived state
  const pickupStopIndex =
    ticket.trip.tripCategory === TripCategory.ShuttlePickUp
      ? ticket.stop.stopIndex
      : 1; // return trips always pickup from first stop

  const { currentStop, currentStatus } = first(trip.routes).routeDispatch;

  const differenceInStopIndexes = pickupStopIndex - currentStop.stopIndex;

  // is a return trip and driver has passed ticket drop off stop
  if (
    ticket.trip.tripCategory === TripCategory.ShuttleReturn &&
    currentStop.stopIndex > ticket.stop.stopIndex
  ) {
    return "Dropped Off";
  }

  // driver is on an earlier stop than the ticket pick up stop
  if (pickupStopIndex > currentStop.stopIndex) {
    return `${differenceInStopIndexes} ${
      differenceInStopIndexes > 1 ? "stops" : "stop"
    } away`;
  }

  // driver is on an later stop than the ticket pick up stop
  if (pickupStopIndex < currentStop.stopIndex) {
    return "Departed";
  }

  // driver is heading to pick up stop
  if (currentStatus === RouteDispatchLogStatusEnum.EnRoute) {
    if (pickupStopIndex === 1) {
      return "Pick-up";
    } else {
      return `Your Pick-Up (${
        ticket.stop.locationAlias || ticket.stop.location
      })`;
    }
  }

  // driver has arrived at pick-up stop
  return "On Location";
};

export default locationTextUtil;
