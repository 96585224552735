import React from "react";
import { useHistory } from "react-router-dom";

import { Box } from "@mui/material";

import { Ticket } from "types";
import { white } from "globals/design-system/colors";
import { useCancelTrip, useCompanyId, useScreenSize } from "globals/hooks";
import { CloseButton } from "components/ui/buttons";
import { Header } from "components/layout/components";
import { Menu } from "components/ui";
import { BoardingPassBody } from "./components";

function BoardingPassPage() {
  // hooks
  const { isMobileView } = useScreenSize();
  const { cancelTrip, loading } = useCancelTrip();
  const history = useHistory<{ ticket: Ticket }>();
  const companyId = useCompanyId();

  // derived state
  const { ticket } = history.location.state || {};

  // event handler
  const handleCancelClick = async () => {
    await cancelTrip(ticket.id);
    handleOnClose();
  };

  const handleOnClose = () => {
    history.push(`/${companyId}/bookings`);
  };

  return (
    <>
      <Header bgColor={white} heightPixels="110px">
        <Box width="100%" maxWidth="780px">
          <Box my={isMobileView ? 3 : 2} mx={isMobileView ? 3 : 0}>
            <Box display="flex">
              <CloseButton onCloseClick={handleOnClose} />
            </Box>
          </Box>
        </Box>
      </Header>

      {/* Screen Body */}
      <Box display="flex" justifyContent="center" mt={3}>
        <Box width="100%" maxWidth="780px">
          <BoardingPassBody
            ticket={ticket}
            onCancelClick={handleCancelClick}
            btnLoading={loading}
          />
        </Box>
      </Box>

      {/* Footer Nav */}
      <Menu />
    </>
  );
}

export default BoardingPassPage;
