/**
 * @file SnackbarAlert.tsx
 * SnackbarAlert component for useSnackbar hook.
 *
 * reactComponents:
 *  SnackbarAlert
 *
 * author: jackv
 */

import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Box, Link, Snackbar, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { black } from "globals/design-system/colors";

export type SnackbarAlertOptions = {
  autoHideDuration?: number;
  open: boolean;
  handleClose: (event: any, reason?: any) => void;
  handleExited: () => void;
  snackbarId: string;
  severity?: "success" | "error" | "info" | "warning";
  message?: string;
  linkLabel?: string;
  linkColor?: string;
  isExternalLink?: boolean;
  link?: string;
  isInlineLink?: boolean;
};

function SnackbarAlert(props: SnackbarAlertOptions) {
  const {
    severity = "info",
    message,
    autoHideDuration,
    open,
    handleClose,
    handleExited,
    snackbarId,
    linkLabel,
    linkColor,
    isExternalLink,
    link,
    isInlineLink,
  } = props;

  const defaults = {
    success: {
      message: "Successfully updated!",
      autoHideDuration: 3000,
    },
    error: {
      message: "There was an error!",
      autoHideDuration: null,
    },
    info: {
      message: "",
      autoHideDuration: 3000,
    },
    warning: {
      message: "",
      autoHideDuration: 3000,
    },
  };

  const linkProps = isExternalLink
    ? { href: link }
    : link
    ? { to: link, component: RouterLink }
    : null;

  const displayedMessage = message || defaults[severity].message;

  // event handler
  const handleLinkClick = (e) => handleClose(e);

  return (
    <Snackbar
      key={snackbarId}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      autoHideDuration={autoHideDuration || defaults[severity].autoHideDuration}
      TransitionProps={{
        onExited: handleExited,
      }}
    >
      <MuiAlert severity={severity} onClose={handleClose}>
        {linkLabel ? (
          <Box display="flex" flexWrap="wrap">
            <Box mr={1}>
              {displayedMessage}
              <Link
                onClick={handleLinkClick}
                style={{
                  color: linkColor || black,
                  cursor: "pointer",
                  textDecoration: "underline",
                  ...(isInlineLink && { marginLeft: "5px" }),
                }}
                {...linkProps}
              >
                <Typography
                  style={{
                    color: linkColor || black,
                    fontWeight: 500,
                    ...(isInlineLink && { display: "inline" }),
                  }}
                >
                  {linkLabel}
                </Typography>
              </Link>
            </Box>
          </Box>
        ) : (
          <>{displayedMessage}</>
        )}
      </MuiAlert>
    </Snackbar>
  );
}

export default SnackbarAlert;
