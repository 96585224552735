import React from "react";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";

import { Box, CircularProgress, Typography } from "@mui/material";

import { black } from "globals/design-system/colors";
import { useLoadCompanyPassengerTickets } from "globals/hooks";
import { LogoWithTextHeader } from "components/layout/components";
import { Menu } from "components/ui";
import { BookingItem, NoBookings } from "./components";

function BookingsPage() {
  // hooks
  const { tickets, loading } = useLoadCompanyPassengerTickets();

  const sortedTickets = sortBy(tickets, (ticket) => {
    const date = ticket.stop.dateTime || ticket.stop.pickUpDateTime;
    return new Date(date).getTime();
  });

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <LogoWithTextHeader>
        <Box width="100%">
          <Typography variant="h2" color={black}>
            My Bookings
          </Typography>
        </Box>
      </LogoWithTextHeader>

      {/* Body */}
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          my="auto"
        >
          <CircularProgress />
        </Box>
      )}

      {!loading && isEmpty(sortedTickets) ? (
        <NoBookings />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
          p={1}
        >
          {sortedTickets.map((ticket) => (
            <BookingItem key={ticket.id} ticket={ticket} />
          ))}
        </Box>
      )}

      {/* Footer Nav */}
      <Menu />
    </Box>
  );
}

export default BookingsPage;
