import { unmaskPhone } from "globals/utils/unmaskPhone";
import * as yup from "yup";

export const loginFormSchema = yup.object({
  mobile: yup
    .string()
    .test({
      name: "Validate phone number",
      message: "Please enter phone number",
      test: function () {
        const { mobile } = this.parent;
        const unmaskedPhone = unmaskPhone(mobile);
        return unmaskedPhone.length === 10;
      },
    })
    .required("Please enter phone number")
    .typeError("Please enter phone number"),
});
