import { useMutation } from "@apollo/client";

import {
  CANCEL_COMPANY_PASSENGER_TICKET_MUTATION,
  LOAD_COMPANY_PASSENGER_TICKETS_QUERY,
} from "globals/graphql";
import { useSnackbar, useAnalytics } from ".";

const useCancelTrip = () => {
  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  const [cancelTripMutation, { loading, data }] = useMutation(
    CANCEL_COMPANY_PASSENGER_TICKET_MUTATION,
    {
      onCompleted() {
        track("shuttleApp_seatCanceled");
        snackbar.success("Successfully cancelled seat!");
      },
      onError() {
        snackbar.error("Error cancelling seat");
      },
      awaitRefetchQueries: true,
      refetchQueries: [LOAD_COMPANY_PASSENGER_TICKETS_QUERY],
    }
  );

  const cancelTrip = (companyPassengerTicketId: string) =>
    cancelTripMutation({
      variables: {
        input: {
          companyPassengerTicketId,
        },
      },
    });

  return { cancelTrip, loading, data };
};

export { useCancelTrip };
