import { useCallback } from "react";

import { useSegment } from "./useSegment";
import { fromGlobalId } from "globals/utils";
import { useCompany } from "./useCompany";

function useAnalytics() {
  // hooks
  const analytics = useSegment();
  const { operator } = useCompany();

  // handlers
  const track = useCallback(
    (name: string, options?: any) => {
      analytics?.track(name, {
        source: "shuttle",
        ...options,
        ...(operator?.id && {
          operator_id: fromGlobalId(operator.id).id,
        }),
      });
    },
    [analytics, operator]
  );

  return { track };
}

export { useAnalytics };
