import React, { ReactNode } from "react";

import { Box, Divider, Typography } from "@mui/material";

import { alabaster } from "globals/design-system/colors";
import { useScreenSize } from "globals/hooks";

type BoardingPassDetailProps = {
  overline: string;
  subtitle: string;
  icon?: ReactNode;
  noDivider?: boolean;
};

function BoardingPassDetail(props: BoardingPassDetailProps) {
  const { overline, subtitle, icon, noDivider } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box mr={isMobileView ? 0 : "80px"}>
      <Typography variant="overline">{overline}</Typography>

      <Box display="flex" alignItems="center" mb={2} mt={0.5}>
        {icon ? <Box sx={{ marginRight: 1 }}>{icon}</Box> : null}
        <Typography variant="subtitle1">{subtitle}</Typography>
      </Box>
      {!noDivider && isMobileView ? (
        <Divider sx={{ backgroundColor: alabaster, marginBottom: 2 }} />
      ) : null}
    </Box>
  );
}

export default BoardingPassDetail;
