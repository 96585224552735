import React from "react";

type TripsIconProps = {
  color?: string;
};

const TripsIcon = (props: TripsIconProps) => {
  const { color } = props;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25 8.57895L17.25 1L9.67105 17L7.98684 10.2632L1.25 8.57895Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TripsIcon;
