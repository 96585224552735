import React, { useMemo, useState, useEffect } from "react";
import moment from "moment";
import sortBy from "lodash/sortBy";
import every from "lodash/every";
import size from "lodash/size";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { black, grayDark } from "globals/design-system/colors";
import { Stop } from "types";
import AvailabilityText from "./AvailabilityText";

type SelectTimeProps = {
  setSelectedStops: React.Dispatch<React.SetStateAction<Stop[]>>;
  inputLabel: string;
  headerTitle: string;
  options: Stop[][]; // each selected date's pickup or return stops
  type: "pick-up" | "return";
  disabled?: boolean;
};

type StopsByTime = {
  [time: string]: Stop[];
};

function SelectTime(props: SelectTimeProps) {
  const { setSelectedStops, headerTitle, inputLabel, options, type, disabled } =
    props;

  // state
  const [selectedTime, setSelectedTime] = useState<string>(null);

  const singleDateSelected = useMemo(() => options.length === 1, [options]);

  const stopsBySharedTime = useMemo(() => {
    const stopsByTime: StopsByTime = {};

    // For all the selected dates, group their stops by time
    options.forEach((stopsForSelectedDate) => {
      stopsForSelectedDate.forEach((stop) => {
        const time = moment
          .utc(stop.dateTime || stop.pickUpDateTime)
          .format("h:mm A");
        stopsByTime[time] = stopsByTime[time] || [];
        stopsByTime[time].push(stop);
      });
    });

    // Get the times that are shared across all selected dates
    const sharedTimes: StopsByTime = {};

    for (const time in stopsByTime) {
      // Assuming each given date should not have multiple stops with identical times,
      // include the time if its number of occurrences is equal to the number of selected dates
      // (ie. each date has exactly one stop with that time)
      if (stopsByTime[time].length === options.length) {
        sharedTimes[time] = stopsByTime[time];
      }
    }

    return sharedTimes;
  }, [options]);

  const sortedTimes = useMemo(
    () =>
      sortBy(Object.keys(stopsBySharedTime), (time) =>
        moment.utc(time, "h:mm A").toDate()
      ),
    [stopsBySharedTime]
  );

  // event handler
  const handleSelectTimeChange = (e) => {
    const newTimeSelected = e.target.value;
    const stops = stopsBySharedTime[newTimeSelected];

    setSelectedTime(newTimeSelected);
    setSelectedStops(stops);
  };

  const stringifiedOptions = useMemo(
    () => JSON.stringify(options),
    [options]
  );

  // Reset selectedTime when options change
  useEffect(() => {
    setSelectedTime(null);
  }, [stringifiedOptions]);

  return (
    <Box mt={2}>
      <Typography variant="h4" mb={1}>
        {headerTitle}
      </Typography>
      <FormControl
        fullWidth
        {...(disabled && {
          disabled,
        })}
      >
        <InputLabel id={`${headerTitle}-label`}>{inputLabel}</InputLabel>
        <Select
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 140,
                overflow: "scroll",
              },
            },
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }}
          labelId={`${headerTitle}-label`}
          id={headerTitle}
          value={selectedTime === null ? "" : selectedTime}
          label={inputLabel}
          onChange={handleSelectTimeChange}
        >
          {size(sortedTimes) === 0 && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              py={2}
              px={3}
              gap={0.5}
            >
              <Typography
                variant="subtitle2"
                textAlign="center"
                color={grayDark}
                width="203px"
              >
                {`No ${type} times available for all selected dates.`}
              </Typography>
              <Typography
                variant="body2"
                textAlign="center"
                color={grayDark}
                width="237px"
              >
                Please select fewer dates to view available times.
              </Typography>
            </Box>
          )}
          {sortedTimes?.map((time) => {
            const stopsForGivenTime = stopsBySharedTime[time];

            const available = every(
              stopsForGivenTime,
              (stop) => !!stop.trip.availableSeats
            );

            return (
              //@ts-ignore - necessary to load object into value
              <MenuItem value={time} key={time} disabled={!available}>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Box>
                    <Typography color={black} variant="body2">
                      {time}
                    </Typography>
                  </Box>
                  <AvailabilityText
                    available={available}
                    singleDateSelected={singleDateSelected}
                    stopsForGivenTime={stopsForGivenTime}
                  />
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectTime;
