/**
 * @file useAuth/Provider.tsx
 * ContextProvider for Authentication
 */
import React, { useEffect, useState, useCallback } from "react";
import { useApolloClient, useLazyQuery } from "@apollo/client";

import authApi from "./utils/authApi";
import AuthContext from "./Context";
import { LOAD_ME_QUERY } from "globals/graphql";
import { fromGlobalId } from "globals/utils";
import { useSegment } from "../useSegment";
import { useCompany } from "../useCompany";
import { unmaskPhone } from "globals/utils/unmaskPhone";

export type HandleLoginParams = {
  code: string;
  maskedPhone: string;
  companyId: string;
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const client = useApolloClient();
  const analytics = useSegment();
  const { operator, id } = useCompany();

  // state
  const [authStage, setAuthStage] = useState<
    "pending" | "authenticated" | "rejected"
  >("pending");

  // queries
  const [loadMeQuery, { data, refetch: refetchLoadMeQuery }] = useLazyQuery(
    LOAD_ME_QUERY,
    {
      onCompleted: (data) => {
        // me query will only return data if valid cookie is found.
        if (data?.loadMe?.mobilePhone && operator?.id) {
          const operatorId = fromGlobalId(operator.id).id;

          analytics?.identify(fromGlobalId(data.loadMe.id).id, {
            operator_id: operatorId,
            email: data.loadMe.email,
            name: `${data.loadMe.firstName} ${data.loadMe.lastName}`,
            source: "shuttle",
          });

          analytics?.group(operatorId, {
            operator_id: operatorId,
            name: operator.name,
            source: "shuttle",
          });

          setAuthStage("authenticated");
        } else {
          handleLogout();
        }
      },
      onError: async (error) => {
        console.error(error);
        await handleLogout();
      },
      fetchPolicy: "network-only",
    }
  );

  // event handlers
  // login / signup
  const handleLogin = useCallback(
    async (params: HandleLoginParams) => {
      const { code, maskedPhone, companyId } = params;

      const { error } = await authApi.login({
        code,
        companyId,
        mobilePhone: unmaskPhone(maskedPhone),
      });

      if (error) return error;

      await refetchLoadMeQuery();
    },
    [refetchLoadMeQuery]
  );

  // logout / clear user cache
  const handleLogout = useCallback(async () => {
    await authApi.logout({ companyId: id });

    // clears cache if coming from authenticated or if it has data
    if (authStage === "authenticated" || data?.loadMe?.mobilePhone) {
      client.clearStore();
    }
    setAuthStage("rejected");
  }, [id, authStage, data, client]);

  // effects
  // mounting app
  useEffect(() => {
    setAuthStage("pending");
    if (operator?.id) {
      loadMeQuery();
    }
  }, [operator, loadMeQuery]);

  return (
    <AuthContext.Provider
      value={{
        authStage,
        onLogin: handleLogin,
        onLogout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
