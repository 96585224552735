import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { Box, Button, Card, Typography } from "@mui/material";

import { Ticket, TripCategory } from "types";
import { useCompanyId, useScreenSize } from "globals/hooks";
import { granite } from "globals/design-system/colors";
import { ArrowReturnIcon, ArrowUpHalfIcon } from "globals/design-system/icons";
import BookingItemDetail from "./BookingItemDetail";
import BookingItemTicketNo from "./BookingItemTicketNo";
import BookingItemLocation from "./BookingItemLocation";

type BookingItemProps = {
  ticket: Ticket;
};

function BookingItem(props: BookingItemProps) {
  const { ticket } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const history = useHistory();
  const companyId = useCompanyId();

  // derived state
  const tripType = ticket.trip.tripCategory;
  const ticketNo = ticket.ticketNumber;
  const bookingDateTime = ticket.stop.dateTime || ticket.stop.pickUpDateTime;
  const bookingLocationText = ticket.stop.locationAlias || ticket.stop.location;

  // map
  const tripTypeMap = {
    [TripCategory.ShuttlePickUp]: {
      value: "Pick-up Trip",
      icon: <ArrowUpHalfIcon color={granite} size="small" />,
    },
    [TripCategory.ShuttleReturn]: {
      value: "Return Trip",
      icon: <ArrowReturnIcon color={granite} size="small" />,
    },
  };

  // events
  const handleOpenBoardingPass = () => {
    history.push(`/${companyId}/boarding-pass`, { ticket });
  };

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        my: 1,
        mx: isMobileView ? 0 : "auto",
        padding: "20px",
        borderRadius: "12px",
        boxShadow:
          "0px 32px 40px -2.5px #18274B05, 0px 12px 14px -2px #18274B0A, 0px 2px 4px -0.8px #18274B0A",
        position: "relative",
        maxWidth: "780px",
        width: "100%",
      }}
    >
      <Box position="absolute" right="20px">
        <BookingItemTicketNo ticketNo={ticketNo} />
      </Box>

      <Box display="flex" flexDirection="column">
        <BookingItemDetail label="trip type" {...tripTypeMap[tripType]} />

        <BookingItemDetail
          label="date & time"
          value={moment(bookingDateTime)
            .utc()
            .format("ddd, MMM DD, YYYY, h:mm A")}
        />

        <BookingItemLocation
          bookingLocationText={bookingLocationText}
          bookingLocation={ticket.stop.location}
        />

        <Button
          variant="contained"
          sx={{ p: 2 }}
          onClick={handleOpenBoardingPass}
        >
          <Typography>Open Boarding Pass</Typography>
        </Button>
      </Box>
    </Card>
  );
}

export default BookingItem;
