/**
 * @file PrivateRoute.tsx
 * Protected route HOC for redirecting unauthenticated users. Wrapper for react-router Route component
 * Reference: https://reacttraining.com/react-router/web/example/auth-workflow
 *
 */

import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";

import { useAuth, useCompanyId } from "globals/hooks";

function PrivateRoute({ children, ...rest }) {
  // hooks
  const { authStage } = useAuth();
  const companyId = useCompanyId();
  const history = useHistory();

  if (authStage === "pending") return null;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        // authentication fails
        if (authStage === "rejected") {
          return (
            <Redirect
              to={{
                pathname: `/${companyId}/login`,
                search: history.location.search,
                state: { from: location },
              }}
            />
          );
        }

        // else
        return children;
      }}
    />
  );
}

export default PrivateRoute;
