import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import compact from "lodash/compact";
import size from "lodash/size";

import { Box, Divider } from "@mui/material";

import ConfirmShuttleTrip from "./ConfirmShuttleTrip";
import { ShuttleTypes, Location } from "pages/authorized/TripsPage/TripsPage";
import { Stop } from "types";
import { useConfirmSeat, useScreenSize, useCompanyId } from "globals/hooks";
import { alabaster } from "globals/design-system/colors";
import { LoadingButton } from "@mui/lab";

type ConfirmSeatBodyProps = {
  pickUpStops: Stop[];
  returnStops: Stop[];
  location: Location;
  refetchCompanyShuttleRoutes: () => void;
};

function ConfirmSeatBody(props: ConfirmSeatBodyProps) {
  const { pickUpStops, returnStops, location, refetchCompanyShuttleRoutes } =
    props;

  // hooks
  const { confirmSeat, data, loading } = useConfirmSeat();
  const { isMobileView } = useScreenSize();
  const history = useHistory();
  const companyId = useCompanyId();

  // event handler
  const handleConfirmSeatClick = () => {
    const pickUpStopIds = pickUpStops.map((stop) => stop.id);
    const returnStopIds = returnStops.map((stop) => stop.id);

    const stopIds = compact([...pickUpStopIds, ...returnStopIds]);

    confirmSeat({
      variables: {
        input: {
          stopIds,
        },
      },
    });
  };

  // useEffect
  useEffect(() => {
    const tickets = data?.confirmCompanyPassengerTickets.tickets;
    if (size(tickets)) {
      refetchCompanyShuttleRoutes();
      history.push(`/${companyId}/bookings`);
    }
  }, [data, history, companyId, refetchCompanyShuttleRoutes]);

  return (
    <Box>
      {!!size(pickUpStops) && (
        <ConfirmShuttleTrip
          stops={pickUpStops}
          location={location}
          shuttleType={ShuttleTypes.PickUpOnly}
        />
      )}
      {!!size(returnStops) && (
        <ConfirmShuttleTrip
          stops={returnStops}
          location={location}
          shuttleType={ShuttleTypes.ReturnOnly}
        />
      )}
      <Box mt={4} mb={3.5}>
        {!isMobileView && <Divider sx={{ backgroundColor: alabaster }} />}
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <LoadingButton
          variant="contained"
          onClick={handleConfirmSeatClick}
          loading={loading}
        >
          Confirm Seat
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default ConfirmSeatBody;
