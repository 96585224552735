import React, { ReactNode } from "react";
import { Box } from "@mui/material";

import { useScreenSize } from "globals/hooks/useScreenSize";
import { grayLight, white } from "globals/design-system/colors";
import { CompanyLogo } from "components/feature";

type LogoWithTextHeaderProps = {
  children: ReactNode;
};

//constants
const HEADER_HEIGHT_PIXELS = "128px";

function LogoWithTextHeader(props: LogoWithTextHeaderProps) {
  const { children } = props;

  //hooks
  const { isMobileView } = useScreenSize();

  if (isMobileView) {
    return (
      <Box
        display="flex"
        height={HEADER_HEIGHT_PIXELS}
        borderBottom={"1px solid " + grayLight}
        bgcolor={white}
      >
        <Box
          display="flex"
          flexDirection="column"
          flexBasis="60%"
          alignItems="flex-start"
          justifyContent="center"
          pl="25px"
        >
          {children}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flexBasis="40%"
          alignItems="flex-end"
          justifyContent="center"
          pr="25px"
        >
          <CompanyLogo size={isMobileView ? "small" : "medium"} />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bgcolor={white}
      borderBottom={"1px solid " + grayLight}
    >
      <Box width="60%">
        <Box display="flex" height={HEADER_HEIGHT_PIXELS}>
          <Box
            display="flex"
            flexDirection="column"
            flexBasis="60%"
            alignItems="flex-start"
            justifyContent="center"
          >
            {children}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flexBasis="40%"
            alignItems="flex-end"
            justifyContent="center"
          >
            <CompanyLogo size={isMobileView ? "small" : "medium"} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default LogoWithTextHeader;
