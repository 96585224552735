import React from "react";

import { Box, Typography } from "@mui/material";

import { granite, grayLight } from "globals/design-system/colors";

type BookingItemTicketNoProps = {
  ticketNo: string;
};

function BookingItemTicketNo(props: BookingItemTicketNoProps) {
  const { ticketNo } = props;

  return (
    <Box px={1} py={0.75} borderRadius={2} bgcolor={grayLight}>
      <Typography variant="overline" color={granite}>
        TICKET NO.
      </Typography>

      <Box display="flex" alignItems="center" mt={0.5}>
        <Typography variant="h6">{ticketNo}</Typography>
      </Box>
    </Box>
  );
}

export default BookingItemTicketNo;
