/**
 * App.tsx
 * Entry point to Moovs Shuttle App.
 * Wraps App with MaterialUI, ReactRouter and custom Swoop Providers.
 */

import React from "react";
import { BrowserRouter } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { SnackbarProvider } from "./globals/hooks/useSnackbar";
import { useForceReload } from "./globals/hooks/useForceReload";
import { SegmentProvider } from "globals/hooks/useSegment";
import Router from "routes/Router";

function App() {
  // force reload on pinned iOS devices when serving stale cached frontend
  useForceReload();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <BrowserRouter>
        <SnackbarProvider>
          <SegmentProvider>
            <Router />
          </SegmentProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </LocalizationProvider>
  );
}

export default App;
