import React from "react";

import { Box, Typography } from "@mui/material";

import ShuttleVehicle from "globals/design-system/illustrations/ShuttleVehicle.svg";

function Greeting() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        component="img"
        src={ShuttleVehicle}
        alt="shuttle-vehicle"
        width="128px"
        mb={1}
      />
      <Box mb={1}>
        <Typography variant="h2" textAlign="center">
          Welcome to the shuttle program
        </Typography>
      </Box>
      <Typography textAlign="center">
        Enter your info below to create an account!
      </Typography>
    </Box>
  );
}

export default Greeting;
