import React, { ReactNode } from "react";

import { Box, Typography } from "@mui/material";

type BookingItemDetailProps = {
  label: string;
  value: string;
  icon?: ReactNode;
};

function BookingItemDetail(props: BookingItemDetailProps) {
  const { label, value, icon } = props;

  return (
    <Box>
      <Typography variant="overline">{label}</Typography>

      <Box display="flex" alignItems="center" mb={2} mt={0.5}>
        {icon ? <Box sx={{ mt: "auto", mr: 1 }}>{icon}</Box> : null}
        <Typography variant="h4">{value}</Typography>
      </Box>
    </Box>
  );
}

export default BookingItemDetail;
