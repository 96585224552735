import React from "react";
import { Box, Typography } from "@mui/material";
import { Ticket, Trip } from "types";

import { white } from "globals/design-system/colors";
import {
  statusBarColorUtil,
  driverTextUtil,
  locationTextUtil,
  etaUtil,
} from "../utils";

type DriverTrackingStatusBarProps = {
  trip: Trip;
  ticket: Ticket;
};

function DriverTrackingStatusBar(props: DriverTrackingStatusBarProps) {
  const { trip, ticket } = props;

  return (
    <Box
      p="10px"
      bgcolor={statusBarColorUtil(ticket, trip)}
      mt={2}
      borderRadius="8px"
    >
      <Box display="flex">
        <Typography
          variant="h6"
          color={white}
          sx={{ verticalAlign: "text-bottom", lineHeight: "15px" }}
        >
          {driverTextUtil(ticket, trip)}
        </Typography>
        <Typography
          pl="4px"
          variant="subtitle2"
          color={white}
          sx={{ verticalAlign: "text-bottom", lineHeight: "15px" }}
        >
          {locationTextUtil(ticket, trip)}
        </Typography>
        <Typography
          variant="caption"
          color={white}
          sx={{
            flexGrow: 1,
            verticalAlign: "text-bottom",
            lineHeight: "15px",
          }}
          align="right"
        >
          {etaUtil(ticket, trip)}
        </Typography>
      </Box>
    </Box>
  );
}

export default DriverTrackingStatusBar;
