/**
 * @file useCurrentCompanyPassenger.ts
 * Hook that abstracts meQuery calls and returns current company passenger.
 */

import { useQuery } from "@apollo/client";

import { useSnackbar } from "./useSnackbar";
import { useAuth } from "./useAuth";
import { LOAD_ME_QUERY } from "globals/graphql";
import { CompanyPassenger } from "types";

function useCurrentCompanyPassenger(): CompanyPassenger {
  const { authStage } = useAuth();
  const snackbar = useSnackbar();

  // queries
  const { data, error } = useQuery(LOAD_ME_QUERY, {
    skip: authStage !== "authenticated",
  });

  if (error) {
    snackbar.error(error.message);
  }

  return data?.loadMe;
}

export { useCurrentCompanyPassenger };
