import React from "react";

const ReturnIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4 13.1992L1 10.7992M1 10.7992L3.4 8.39922M1 10.7992H10.6C11.2365 10.7992 11.847 10.5464 12.2971 10.0963C12.7471 9.64619 13 9.03574 13 8.39922V3.59922C13 2.9627 12.7471 2.35225 12.2971 1.90216C11.847 1.45208 11.2365 1.19922 10.6 1.19922H6.2"
        stroke="#565656"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ReturnIcon;
