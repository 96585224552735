import { white } from "globals/design-system/colors";
import { Stop } from "types";

type CreateTripStopMarkerParams = {
  stop: Stop;
  map: google.maps.Map;
  svg: any;
  label: number | null | string;
  anchor?: google.maps.Point;
  labelOrigin?: google.maps.Point;
  scaledSize?: google.maps.Size;
  className?: string;
};

export const createTripStopMarker = (params: CreateTripStopMarkerParams) => {
  const {
    stop: { coordinates, stopIndex },
    map,
    svg,
    label,
    anchor,
    labelOrigin,
    scaledSize,
    className,
  } = params;

  const tripLocationMarker = new google.maps.Marker({
    position: { lat: coordinates.x, lng: coordinates.y },
    map: map,
    icon: {
      url: svg,
      anchor: anchor || new google.maps.Point(10, 4),
      labelOrigin: labelOrigin || new google.maps.Point(12, -12),
      scaledSize: scaledSize || new google.maps.Size(15, 15),
    },
    label: label
      ? {
          text: label.toString(),
          color: white,
          fontWeight: "600",
          fontSize: "12px",
          fontFamily: "Poppins",
          ...(className && { className }),
        }
      : null,
  });
  tripLocationMarker.set("stopIndex", stopIndex);

  return tripLocationMarker;
};
