import React, { Dispatch, SetStateAction } from "react";
import uniqBy from "lodash/uniqBy";
import moment from "moment";

import { Box } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import { Stop } from "types";
import { TRIPS_PAGE_SCREEN_WIDTH, Screen, Location } from "../../TripsPage";
import { ConfirmSeatHeader, ConfirmSeatBody } from "./components";

type ConfirmSeatScreenProps = {
  pickUpStops: Stop[];
  returnStops: Stop[];
  location: Location;
  setCurrentScreen: Dispatch<SetStateAction<Screen>>;
  refetchCompanyShuttleRoutes: () => void;
};

function ConfirmSeatScreen(props: ConfirmSeatScreenProps) {
  const {
    pickUpStops,
    returnStops,
    location,
    setCurrentScreen,
    refetchCompanyShuttleRoutes,
  } = props;

  const pickUpDates = pickUpStops.map((stop) => stop.dateTime);
  const returnDates = returnStops.map((stop) => stop.pickUpDateTime);

  const dates = uniqBy([...pickUpDates, ...returnDates], (date) =>
    moment.utc(date).format("YYYY-MM-DD")
  );

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <>
      {/* Screen Header */}
      <ConfirmSeatHeader
        setCurrentScreen={setCurrentScreen}
        dates={dates}
        refetchCompanyShuttleRoutes={refetchCompanyShuttleRoutes}
      />

      {/* Screen Body */}
      <Box display="flex" justifyContent="center">
        <Box
          width="100vw"
          maxWidth={TRIPS_PAGE_SCREEN_WIDTH}
          m={isMobileView ? 3 : 2}
          p={2}
        >
          <ConfirmSeatBody
            pickUpStops={pickUpStops}
            returnStops={returnStops}
            location={location}
            refetchCompanyShuttleRoutes={refetchCompanyShuttleRoutes}
          />
        </Box>
      </Box>
    </>
  );
}

export default ConfirmSeatScreen;
