import React from "react";
import { Box, Typography } from "@mui/material";

import {
  alabaster,
  granite,
  grayLight,
  moovsBlueSelected,
} from "globals/design-system/colors";
import Map from "./Map/Map"; //TO-DO make indexes
import { PickUpIcon, ReturnIcon } from "globals/design-system/icons";
import { useScreenSize } from "globals/hooks/useScreenSize";
import { CompanyPassenger, Ticket, TripCategory } from "types";
import moment from "moment";
import { useLoadDriverTrackingData } from "globals/hooks";
import first from "lodash/first";
import DriverTrackingStatusBar from "./DriverTrackingStatusBar";
import GoogleMapsLocationButton from "components/ui/buttons/GoogleMapsLocationButton";

const MAX_SCREEN_WIDTH = "720px";

type UpcomingTripProps = {
  ticket: Ticket;
  passenger: CompanyPassenger;
};

function UpcomingTrip(props: UpcomingTripProps) {
  const { ticket, passenger } = props;

  //hooks
  const { isMobileView } = useScreenSize();
  const { driverTrackingData, loading } = useLoadDriverTrackingData();

  const pickUpDateTime =
    ticket.trip.tripCategory === TripCategory.ShuttlePickUp
      ? ticket.stop.dateTime
      : first(ticket.trip.stops).dateTime;

  // derived state
  const isTripInProgress =
    driverTrackingData?.routes[0].routeDispatch.currentStop &&
    driverTrackingData?.routes[0].dispatchStatus !== "done";

  const stopLocationText = ticket.stop.locationAlias || ticket.stop.location;

  return (
    <Box display="flex" justifyContent="center">
      <Box
        width="100vw"
        maxWidth={MAX_SCREEN_WIDTH}
        ml={isMobileView ? 1 : 2}
        mr={isMobileView ? 1 : 2}
        pl={2}
        pr={2}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          {/*check that driverTrackingData is present and that dispatch isn't done or pending and that dispatch hasn't passed the passenger's stop*/}
          {/*also checks if is a shuttle return the driver hasn't passed stop 1*/}
          {!loading && driverTrackingData && isTripInProgress && (
            <>
              <Box width="100%">
                <DriverTrackingStatusBar
                  trip={driverTrackingData}
                  ticket={ticket}
                />
              </Box>
              <Box width="100%" height="250px" marginTop="15px">
                <Map trip={driverTrackingData} ticket={ticket} />
              </Box>
            </>
          )}
          <Box
            width="100%"
            bgcolor={
              !loading && driverTrackingData && isTripInProgress
                ? moovsBlueSelected
                : alabaster
            }
            borderRadius="8px"
            padding="5px"
            margin="15px"
          >
            <Box p="5px" borderBottom={"1px solid " + grayLight}>
              <Typography variant="h5" color={granite}>
                DATE & TIME
              </Typography>
              <Typography variant="h4" pt="5px">
                {moment.utc(pickUpDateTime).format("dddd, MMMM D,")}
              </Typography>
              <Typography variant="h4" pb="5px">
                {moment.utc(pickUpDateTime).format("YYYY h:mm A")}
              </Typography>
            </Box>
            <Box p="5px" borderBottom={"1px solid " + grayLight}>
              {ticket.trip.tripCategory === TripCategory.ShuttlePickUp ? (
                <>
                  <Typography variant="h5" color={granite}>
                    PICK-UP
                  </Typography>
                  <Box py="5px">
                    <GoogleMapsLocationButton
                      location={ticket.stop.location}
                      locationText={stopLocationText}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Typography variant="h5" color={granite}>
                    DROP-OFF
                  </Typography>
                  <Box py="5px">
                    <GoogleMapsLocationButton
                      location={ticket.stop.location}
                      locationText={stopLocationText}
                    />
                  </Box>
                </>
              )}
            </Box>
            <Box p="5px" borderBottom={"1px solid " + grayLight}>
              <Typography variant="h5" color={granite}>
                PASSENGER
              </Typography>
              <Typography variant="h4" pt="5px" pb="5px">
                {passenger.firstName + " " + passenger.lastName}
              </Typography>
            </Box>
            <Box p="5px">
              <Typography variant="h5" color={granite}>
                TRIP TYPE
              </Typography>
              {ticket.trip.tripCategory === TripCategory.ShuttlePickUp ? (
                <Box display="flex" alignItems="center">
                  <PickUpIcon />
                  <Typography variant="h4" pt="5px" pb="5px" ml={1}>
                    Pick-up Trip
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <ReturnIcon />
                  <Typography variant="h4" pt="5px" pb="5px" ml={1}>
                    Return Trip
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default UpcomingTrip;
