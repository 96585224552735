import React, { useCallback, useEffect, useState, useMemo } from "react";
import size from "lodash/size";
import every from "lodash/every";

import { Box, Typography, CircularProgress } from "@mui/material";

import { TripTypeToggle } from "./components";
import { ShuttleTypes } from "pages/authorized/TripsPage/TripsPage";
import SelectTime from "./components/SelectTime";
import { SelectedDateData } from "../SelectDates";
import { CompanyShuttleRoute, ShuttleStopLocation, Stop } from "types";
import { grayDark } from "globals/design-system/colors";
import { useLoadCompanyPassengerTickets } from "globals/hooks";

type SelectTripTypeProps = {
  selectedDates: SelectedDateData[];
  selectedPickupLocation: ShuttleStopLocation;
  selectedCompanyShuttleRoute: CompanyShuttleRoute;
  setShuttleType: React.Dispatch<React.SetStateAction<ShuttleTypes>>;
  shuttleType: ShuttleTypes;
  setPickUpStops: React.Dispatch<React.SetStateAction<Stop[]>>;
  setReturnStops: React.Dispatch<React.SetStateAction<Stop[]>>;
};

function SelectTripType(props: SelectTripTypeProps) {
  const {
    selectedDates,
    selectedPickupLocation,
    selectedCompanyShuttleRoute,
    setShuttleType,
    shuttleType,
    setPickUpStops,
    setReturnStops,
  } = props;

  // hooks
  const { tickets: existingTickets, loading: loadingExistingTickets } =
    useLoadCompanyPassengerTickets();

  const tripIdsForExistingTickets = useMemo(
    () => existingTickets.map((ticket) => ticket.trip.id),
    [existingTickets]
  );

  // state
  const [selectedPickUpTimeStops, setSelectedPickUpTimeStops] = useState<
    Stop[]
  >([]);
  const [selectedReturnTimeStops, setSelectedReturnTimeStops] = useState<
    Stop[]
  >([]);

  const hasSelectedDates = !!size(selectedDates);

  const optionsByShuttleType = useCallback(
    (type: "pickUpTime" | "returnTime") => {
      if (!hasSelectedDates) {
        return [];
      }

      return selectedDates.map((selectedDate) =>
        // Filter out stops for trips where the passenger already has a ticket
        selectedDate[type].filter(
          (time) => !tripIdsForExistingTickets.includes(time.trip.id)
        )
      );
    },
    [hasSelectedDates, selectedDates, tripIdsForExistingTickets]
  );

  const allDatesHavePickUp =
    hasSelectedDates && every(selectedDates, (date) => !!size(date.pickUpTime));
  const allDatesHaveReturn =
    hasSelectedDates && every(selectedDates, (date) => !!size(date.returnTime));

  // hide trip type toggles and time select inputs if user has selected multiple dates
  // but they dont have overlapping trip types
  // (ie. not all of them have pick up AND not all of them have return)
  const hideInputs =
    hasSelectedDates && !allDatesHavePickUp && !allDatesHaveReturn;

  // useCallback
  const resetStops = useCallback(() => {
    setSelectedPickUpTimeStops([]);
    setSelectedReturnTimeStops([]);
    setPickUpStops([]);
    setReturnStops([]);
  }, [setPickUpStops, setReturnStops]);

  // even handler
  const handleToggleButtonChange = (variant) => {
    setShuttleType(variant);
    resetStops();
  };

  // effects
  useEffect(() => {
    if (allDatesHavePickUp && allDatesHaveReturn) {
      setShuttleType(ShuttleTypes.PickUpAndReturn);
    } else if (allDatesHavePickUp) {
      setShuttleType(ShuttleTypes.PickUpOnly);
    } else if (allDatesHaveReturn) {
      setShuttleType(ShuttleTypes.ReturnOnly);
    }
  }, [allDatesHavePickUp, allDatesHaveReturn, setShuttleType]);

  // reset stops and shuttle type when new route, location, or dates are selected
  useEffect(() => {
    if (selectedCompanyShuttleRoute || selectedPickupLocation) {
      resetStops();
      if (!allDatesHavePickUp && !allDatesHaveReturn) {
        setShuttleType(ShuttleTypes.PickUpAndReturn);
      }
    }
  }, [
    selectedCompanyShuttleRoute,
    selectedPickupLocation,
    selectedDates,
    setPickUpStops,
    setReturnStops,
    allDatesHavePickUp,
    allDatesHaveReturn,
    setShuttleType,
    resetStops,
  ]);

  // if time is selected, set up pickUpStops and returnStops for mutation
  useEffect(() => {
    if (!!size(selectedPickUpTimeStops)) {
      setPickUpStops(selectedPickUpTimeStops);
    }
    if (!!size(selectedReturnTimeStops)) {
      setReturnStops(selectedReturnTimeStops);
    }
  }, [
    selectedPickUpTimeStops,
    selectedReturnTimeStops,
    setPickUpStops,
    setReturnStops,
  ]);

  if (hideInputs) {
    return (
      <Box mt={3} display="flex" justifyContent="center">
        <Typography variant="subtitle2" mb={0} color={grayDark}>
          No trip types available for all selected dates
        </Typography>
      </Box>
    );
  }

  if (loadingExistingTickets) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" my={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box mt={2}>
        <Typography variant="h4" mb={1.5}>
          Trip Type
        </Typography>
        <TripTypeToggle
          onToggleButtonChange={handleToggleButtonChange}
          shuttleType={shuttleType}
          allDatesHavePickUp={allDatesHavePickUp}
          allDatesHaveReturn={allDatesHaveReturn}
        />
        {shuttleType === ShuttleTypes.PickUpAndReturn && (
          <>
            <SelectTime
              setSelectedStops={setSelectedPickUpTimeStops}
              headerTitle="Pick-up Time"
              inputLabel="Select Pick-up Time"
              type="pick-up"
              options={optionsByShuttleType("pickUpTime")}
              disabled={!hasSelectedDates ? true : false}
            />

            <SelectTime
              setSelectedStops={setSelectedReturnTimeStops}
              headerTitle="Return Time"
              inputLabel="Select Return Time"
              type="return"
              options={optionsByShuttleType("returnTime")}
              disabled={!hasSelectedDates ? true : false}
            />
          </>
        )}

        {shuttleType === ShuttleTypes.PickUpOnly && (
          <SelectTime
            setSelectedStops={setSelectedPickUpTimeStops}
            headerTitle="Pick-up Time"
            inputLabel="Select Pick-up Time"
            type="pick-up"
            options={optionsByShuttleType("pickUpTime")}
          />
        )}

        {shuttleType === ShuttleTypes.ReturnOnly && (
          <SelectTime
            setSelectedStops={setSelectedReturnTimeStops}
            headerTitle="Return Time"
            inputLabel="Select Return Time"
            type="return"
            options={optionsByShuttleType("returnTime")}
          />
        )}
      </Box>
    </>
  );
}

export default SelectTripType;
