import React from "react";

type CloseIconIconProps = {
  color?: string;
  size?: "small";
};

const CloseIcon = (props: CloseIconIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="11"
        height="12"
        viewBox="0 0 11 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.1666 1.53125L5.49992 6.19792M5.49992 6.19792L0.833252 10.8646M5.49992 6.19792L0.833252 1.53125M5.49992 6.19792L10.1666 10.8646"
          stroke={color || "#999999"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1666 1.53125L5.49992 6.19792M5.49992 6.19792L0.833252 10.8646M5.49992 6.19792L0.833252 1.53125M5.49992 6.19792L10.1666 10.8646"
        stroke={color || "#999999"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseIcon;
