import React from "react";

import { Button } from "@mui/material";

import { moovsBlue } from "globals/design-system/colors";
import { CloseIcon } from "globals/design-system/icons";

type CloseButtonProps = {
  onCloseClick: () => void;
};

function CloseButton(props: CloseButtonProps) {
  const { onCloseClick } = props;

  return (
    <Button
      onClick={onCloseClick}
      color="primary"
      startIcon={<CloseIcon color={moovsBlue} />}
    >
      Close
    </Button>
  );
}

export default CloseButton;
