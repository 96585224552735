import React, { ReactNode } from "react";
import { Box } from "@mui/material";

import { alabaster, grayLight } from "globals/design-system/colors";

export const FOOTER_HEIGHT = "90px";

type FooterProps = {
  children: ReactNode;
};

function Footer(props: FooterProps) {
  const { children } = props;

  return (
    <>
      <Box position="fixed" bottom={0} width={1} zIndex={100}>
        <Box
          display="flex"
          height={FOOTER_HEIGHT}
          alignItems="center"
          justifyContent="center"
          borderTop={"1px solid " + grayLight}
          bgcolor={alabaster}
        >
          {children}
        </Box>
      </Box>
      <Box height={FOOTER_HEIGHT} bottom={0} />
    </>
  );
}

export default Footer;
