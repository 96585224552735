import React from "react";

import { MoovsToggleButtonGroup } from "components/ui/buttons";
import {
  ArrowRoundTripIcon,
  ArrowUpHalfIcon,
} from "globals/design-system/icons";
import { ShuttleTypes } from "pages/authorized/TripsPage/TripsPage";
import ArrowReturn from "globals/design-system/icons/ArrowReturn";

type TripTypeToggleProps = {
  onToggleButtonChange: (variant: ShuttleTypes) => void;
  shuttleType: ShuttleTypes;
  allDatesHavePickUp: boolean;
  allDatesHaveReturn: boolean;
};

function TripTypeToggle(props: TripTypeToggleProps) {
  const {
    onToggleButtonChange,
    shuttleType,
    allDatesHavePickUp,
    allDatesHaveReturn,
  } = props;

  const pickUpAndReturnButton = {
    value: ShuttleTypes.PickUpAndReturn,
    ariaLabel: "pickUpAndReturn",
    label: "Pick-up AND Return",
    icon: ArrowRoundTripIcon,
  };

  const pickUpOnly = {
    value: ShuttleTypes.PickUpOnly,
    ariaLabel: "pickUpOnly",
    label: "Only pick-up to office",
    icon: ArrowUpHalfIcon,
  };

  const returnOnly = {
    value: ShuttleTypes.ReturnOnly,
    ariaLabel: "returnOnly",
    label: "Only return from office",
    icon: ArrowReturn,
  };

  // set selector buttons
  let toggleButtons;
  if (
    (allDatesHavePickUp && allDatesHaveReturn) ||
    (!allDatesHavePickUp && !allDatesHaveReturn)
  ) {
    toggleButtons = [pickUpAndReturnButton, pickUpOnly, returnOnly];
  } else if (allDatesHavePickUp) {
    toggleButtons = [pickUpOnly];
  } else if (allDatesHaveReturn) {
    toggleButtons = [returnOnly];
  } else {
    toggleButtons = null;
  }

  return toggleButtons ? (
    <MoovsToggleButtonGroup
      wrapLabel
      options={toggleButtons}
      onChange={onToggleButtonChange}
      value={shuttleType}
      disabledGroup={!allDatesHavePickUp && !allDatesHaveReturn}
    />
  ) : null;
}

export default TripTypeToggle;
