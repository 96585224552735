/**
 * @file useScreenSize.ts
 * Hook that returns size of screen
 */
import { useMediaQuery } from "@mui/material";
import theme from "globals/design-system/theme";

function useScreenSize() {
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isSmallTabletView = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const isMediumTabletView = useMediaQuery(theme.breakpoints.down("lg"), {
    noSsr: true,
  });

  return {
    isMobileView,
    isSmallTabletView,
    isMediumTabletView,
  };
}

export { useScreenSize };
