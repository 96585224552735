import React from "react";
import { Box, Typography } from "@mui/material";
import { granite } from "globals/design-system/colors";
import { NoTripsIcon } from "globals/design-system/icons";

function NoUpcomingTrip() {
  return (
    <Box
      paddingTop="85px"
      display="flex"
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <NoTripsIcon />
      <Typography pt="15px" variant="h2">
        No upcoming trips
      </Typography>
      <Typography pt="15px" variant="body1" color={granite}>
        You currently have no upcoming trips.
      </Typography>
      <Typography pt="5px" variant="body1" color={granite}>
        Go to trips screen to book a seat!
      </Typography>
    </Box>
  );
}

export default NoUpcomingTrip;
