import * as yup from "yup";
import isEmail from "validator/lib/isEmail";

import { unmaskPhone } from "globals/utils/unmaskPhone";

export const signUpFormSchema = yup.object({
  firstName: yup
    .string()
    .required("Please enter first name")
    .typeError("Please enter first name"),
  lastName: yup
    .string()
    .required("Please enter last name")
    .typeError("Please enter last name"),
  mobile: yup
    .string()
    .test({
      name: "Validate phone number",
      message: "Please enter phone number",
      test: function () {
        const { mobile } = this.parent;
        const unmaskedPhone = unmaskPhone(mobile);
        return unmaskedPhone.length === 10;
      },
    })
    .required("Please enter phone number")
    .typeError("Please enter phone number"),
  email: yup.string().test({
    name: "Validate email",
    message: "Please enter a valid email",
    test: function () {
      const { email } = this.parent;
      return isEmail(email)
    },
  })
  .required("Please enter email")
  .typeError("Please enter email"),
});
