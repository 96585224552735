import React from "react";

function NoTrips() {
  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="140" height="140" rx="70" fill="#E5EEFF" />
      <g opacity="0.5">
        <path
          d="M49 60.881C42.8517 66.5459 39 74.6655 39 83.6848C39 92.704 42.8517 100.824 49 106.489L49 60.881Z"
          fill="url(#paint0_linear_2026_363449)"
        />
        <path
          d="M101 83.6848C101 74.186 96.7278 65.685 90 59.9985L90 107.371C96.7278 101.685 101 93.1836 101 83.6848Z"
          fill="url(#paint1_linear_2026_363449)"
        />
      </g>
      <path
        opacity="0.5"
        d="M72.1141 108L86 108C88.2091 108 90 106.209 90 104L90 38.476C90 37.3129 89.2079 36.2992 88.0793 36.018C86.6951 35.6732 85.2865 35.4351 83.8659 35.306L80.5 35C79.8433 36.9701 78.7149 38.7498 77.2132 40.1841L76.9149 40.469L62.0851 40.469C60.7097 39.1702 59.6155 37.6028 58.8703 35.864L58.5 35L54.7064 35C52.9265 35 51.1709 35.4136 49.5782 36.2081C49.2239 36.3849 49 36.7468 49 37.1427L49 104C49 106.209 50.7909 108 53 108L72.1141 108Z"
        fill="url(#paint2_linear_2026_363449)"
      />
      <path
        d="M59 35V34.25C58.5858 34.25 58.25 34.5858 58.25 35H59ZM80 35H80.75C80.75 34.5858 80.4142 34.25 80 34.25V35ZM59 35.75H80V34.25H59V35.75ZM79.25 35V36H80.75V35H79.25ZM76 39.25H63V40.75H76V39.25ZM59.75 36V35H58.25V36H59.75ZM63 39.25C61.2051 39.25 59.75 37.7949 59.75 36H58.25C58.25 38.6234 60.3766 40.75 63 40.75V39.25ZM79.25 36C79.25 37.7949 77.7949 39.25 76 39.25V40.75C78.6234 40.75 80.75 38.6234 80.75 36H79.25Z"
        fill="#195FE9"
      />
      <path
        d="M58 94C58 87.25 58.1955 81.7913 65.6521 78.6591C70.5216 76.6137 73.6522 77.6364 73.6522 71.5C73.6522 68.0909 75.7627 65.3636 78.8932 65.3636C80.1685 65.3636 82 64.75 82 62.2955C82 59.8409 82 58.9091 82 58"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="82"
        cy="55"
        r="3"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="58"
        cy="92"
        r="3"
        fill="#195FE9"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="49"
        y="35"
        width="41"
        height="73"
        rx="4"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56 58.5789L72 51L64.4211 67L62.7368 60.2632L56 58.5789Z"
        fill="white"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2026_363449"
          x1="69.9966"
          y1="59.9661"
          x2="69.9966"
          y2="107.372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#195FE9" stopOpacity="0.04" />
          <stop offset="1" stopColor="#87AAFF" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2026_363449"
          x1="69.9966"
          y1="59.9661"
          x2="69.9966"
          y2="107.372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#195FE9" stopOpacity="0.04" />
          <stop offset="1" stopColor="#87AAFF" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2026_363449"
          x1="69.4978"
          y1="36.4477"
          x2="69.4978"
          y2="108.002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#195FE9" />
          <stop offset="1" stopColor="#87AAFF" stopOpacity="0.62" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default NoTrips;
