// error codes and their respective user friendly error messages. Messaging unique to each application (operator, driver, customer)
export const ErrorMessages = {
  // default error codes
  MOOVS_INTERNAL_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.",
  MOOVS_USER_INPUT_ERROR: "Please provide a valid value.",
  MOOVS_NOT_FOUND_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.",
  MOOVS_AUTHENTICATION_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.",
  MOOVS_FORBIDDEN_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.",
  MOOVS_UNPROCESSABLE_ENTITY_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.",
  MOOVS_UNREACHABLE_CASE_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.", //(this one is predominately griddnet related at the moment)
  MOOVS_BAD_REQUEST_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.",
  MOOVS_API_REQUEST_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.",

  // Shuttle error codes
  MOOVS_NO_AVAILABLE_SEATS_ERROR:
    "Seat no longer available. Please select another time slot with available seats.",
};
