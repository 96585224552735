import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box } from "@mui/material";

import { signUpFormDefaultValues } from "./util/formDefaultValues";
import { signUpFormSchema } from "./util/formSchemaValidation";
import { RHFMobileField } from "components/ui/react-hook-form";
import { RHFMobileFieldMaskEnum } from "components/ui/react-hook-form/RHFMobileField";
import RHFTextField from "components/ui/react-hook-form/RHFTextfield";
import {
  useAnalytics,
  useLoadCompanyForUnauthenticated,
  useSnackbar,
} from "globals/hooks";
import { unmaskPhone } from "globals/utils/unmaskPhone";
import { useHistory } from "react-router-dom";
import authApi from "globals/hooks/useAuth/utils/authApi";
import { LoadingButton } from "@mui/lab";
import { moovsBlue } from "globals/design-system/colors";

function SignUpForm() {
  // state
  const [loading, setLoading] = useState(false);

  // hooks
  const { track } = useAnalytics();
  const history = useHistory();
  const snackbar = useSnackbar();
  const { unauthenticatedCompany } = useLoadCompanyForUnauthenticated();

  // set up react hook form
  const {
    control,
    formState: { errors },
    handleSubmit: validateSignUpForm,
    watch,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: signUpFormDefaultValues,
    resolver: yupResolver(signUpFormSchema),
  });

  // event handlers

  // submit form will be called when all inputs have passed the yup schema validation
  const submitForm = async () => {
    const { firstName, lastName, mobile, email } = watch();
    setLoading(true);

    const { status, error } = await authApi.createPassenger({
      firstName,
      lastName,
      mobilePhone: unmaskPhone(mobile),
      companyId: unauthenticatedCompany.id,
      email,
    });

    setLoading(false);

    if (status !== 200) {
      const userExists = status === 409;

      snackbar.error(error.message, {
        ...(userExists && {
          link: `/${unauthenticatedCompany.id}/login`,
          linkLabel: "Log In",
          linkColor: moovsBlue,
          isInlineLink: true,
        }),
      });
    } else {
      track("shuttleApp_accountCreated");
      history.push(`/${unauthenticatedCompany.id}/verify-code`, {
        maskedPhone: mobile,
      });
    }
  };

  // clicking on Create Account
  const handleSignUpClick = () => {
    validateSignUpForm(submitForm)();
  };

  return (
    <form>
      <Box my={1.5}>
        <RHFTextField
          name="firstName"
          control={control}
          errors={errors}
          label="Enter First Name"
        />
      </Box>
      <Box my={1.5}>
        <RHFTextField
          name="lastName"
          control={control}
          errors={errors}
          label="Enter Last Name"
        />
      </Box>
      <Box my={1.5}>
        <RHFMobileField
          name="mobile"
          control={control}
          errors={errors}
          mask={RHFMobileFieldMaskEnum.Standard}
          label="Enter Mobile Phone Number"
        />
      </Box>
      <Box my={1.5}>
        <RHFTextField
          name="email"
          control={control}
          errors={errors}
          label="Enter Email"
        />
      </Box>
      <LoadingButton
        fullWidth
        onClick={handleSignUpClick}
        loading={loading}
        variant="contained"
      >
        Create Account
      </LoadingButton>
    </form>
  );
}

export default SignUpForm;
