import { useCallback, useEffect, useState } from "react";

import { TrackedDriver } from "./types";
import { setIconPathByRotation } from "../utils/setIconPathByRotation";
import { Ticket, Trip, TripCategory } from "types";
import first from "lodash/first";
import last from "lodash/last";

type UseActiveDriverIconProps = {
  map: google.maps.Map;
  trackedDrivers: TrackedDriver[];
  trip: Trip;
  ticket: Ticket;
};

function useActiveDriverIcon(props: UseActiveDriverIconProps) {
  const { map, trackedDrivers, trip, ticket } = props;

  // state
  const [driverMarkers, setDriverMarkers] = useState<
    Map<string, google.maps.Marker>
  >(new Map());

  const setMapBound = useCallback(() => {
    if (trackedDrivers.length === 0) return;

    const bounds = new google.maps.LatLngBounds();

    // set driver map bound
    trackedDrivers.forEach(
      ({
        location: {
          coords: { latitude: lat, longitude: lng },
        },
      }) => {
        if (lat && lng) {
          bounds.extend({ lat, lng });
        }
      }
    );

    // set stop map bound
    const { currentStop } = first(trip.routes).routeDispatch;

    // shuttle pick up
    if (trip.tripCategory === TripCategory.ShuttlePickUp) {
      // driver has passed pick up, show drop-off
      if (currentStop.stopIndex > ticket.stop.stopIndex) {
        bounds.extend({
          lat: last(trip.stops).coordinates.x,
          lng: last(trip.stops).coordinates.y,
        });
        //driver has not passed pick up, show employee's pick up
      } else {
        bounds.extend({
          lat: ticket.stop.coordinates.x,
          lng: ticket.stop.coordinates.y,
        });
      }
    } else {
      // shuttle return

      //driver has not passed pick up, show pick up
      if (currentStop.stopIndex === 1) {
        bounds.extend({
          lat: first(trip.stops).coordinates.x,
          lng: first(trip.stops).coordinates.y,
        });
        //driver has passed pick up, show employee's drop off
      } else {
        bounds.extend({
          lat: ticket.stop.coordinates.x,
          lng: ticket.stop.coordinates.y,
        });
      }
    }

    if (map) {
      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
    }
  }, [trackedDrivers, map, ticket, trip]);

  // add, remove, and update drivers by comparing previous markers to incoming trackedDrivers
  useEffect(() => {
    let shouldResetMapBounds = false;
    let shouldUpdateDriverMarkers = false;
    const newDriverMarkers = new Map();
    trackedDrivers.forEach(
      ({
        id,
        firstName,
        location: {
          coords: { latitude: lat, longitude: lng, heading },
          timestamp: currentTimeStamp,
        },
      }) => {
        if (lat && lng) {
          // if trackedDriver id does not exist in previous markers, create new Marker
          if (!driverMarkers.has(id)) {
            shouldResetMapBounds = true;
            shouldUpdateDriverMarkers = true;
            const marker = new google.maps.Marker({
              position: { lat, lng },
              icon: createDriverIcon(heading),
              label: firstName,
              map: map,
              zIndex: 100,
            });
            marker.set("timestamp", currentTimeStamp);
            newDriverMarkers.set(id, marker);
          } else {
            // if trackedDriver id exists in previous markers, and timestamp is different (change of coordinates), update Marker
            const previousMarker = driverMarkers.get(id);
            if (currentTimeStamp !== previousMarker["timestamp"]) {
              shouldUpdateDriverMarkers = true;
              previousMarker.setPosition({ lat, lng });
              previousMarker.setIcon(createDriverIcon(heading));
              previousMarker.setLabel(firstName);
              previousMarker.set("timestamp", currentTimeStamp);
            }
            newDriverMarkers.set(id, previousMarker);
          }
        }
      }
    );

    // remove inactive drivers on map
    driverMarkers.forEach((prevMarker, prevDriverId) => {
      if (!newDriverMarkers.has(prevDriverId)) {
        shouldUpdateDriverMarkers = true;
        shouldResetMapBounds = true;
        prevMarker.setMap(null);
      }
    });

    if (shouldResetMapBounds) setMapBound();
    if (shouldUpdateDriverMarkers) setDriverMarkers(newDriverMarkers);
  }, [trackedDrivers, map, setMapBound, driverMarkers]);
}

const createDriverIcon = (rotation: number) => {
  return {
    url: setIconPathByRotation(rotation),
    anchor: new google.maps.Point(12, 20),
    labelOrigin: new google.maps.Point(16, 42),
    scaledSize: new google.maps.Size(32, 32),
  };
};

export default useActiveDriverIcon;
