import React from "react";

import { Typography, Box } from "@mui/material";

import { useLoadCompanyForUnauthenticated } from "globals/hooks/useLoadCompanyForUnauthenticated";
import { black, granite } from "globals/design-system/colors";

type CompanyLogoProps = {
  size?: "small" | "medium";
};

function CompanyLogo(props: CompanyLogoProps) {
  const { size } = props;

  // hooks
  const { unauthenticatedCompany, loading } =
    useLoadCompanyForUnauthenticated();

  if (!unauthenticatedCompany || loading) return null;

  if (!unauthenticatedCompany.companyLogoUrl) {
    return (
      <Box>
        <Typography
          variant="h4"
          style={size === "small" ? { color: black } : { color: granite }}
        >
          {unauthenticatedCompany.name}
        </Typography>
      </Box>
    );
  }

  return (
    <img
      src={unauthenticatedCompany.companyLogoUrl}
      alt={unauthenticatedCompany.name}
      style={{ maxHeight: "80%", maxWidth: "90%" }}
    />
  );
}

export default CompanyLogo;
