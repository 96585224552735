import React from "react";

import { Typography, Box } from "@mui/material";

import { alabaster, grayLight } from "globals/design-system/colors";
import {
  BoardingPass,
  BoardingPassCircular,
} from "globals/design-system/illustrations";
import { useScreenSize } from "globals/hooks";

type BoardingPassTicketNumberProps = {
  ticketNumber: string;
};

function BoardingPassTicketNumber(props: BoardingPassTicketNumberProps) {
  const { ticketNumber } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobileView ? "column" : "flex",
        justifyContent: "space-between",
        backgroundColor: alabaster,
        padding: 3,
        paddingBottom: 4,
      }}
    >
      <Box sx={{ marginBottom: isMobileView ? 2 : 0 }}>
        {isMobileView ? <BoardingPass /> : <BoardingPassCircular />}
        <Typography variant="h2">Boarding Pass</Typography>
      </Box>
      <Box>
        <Typography
          variant="overline"
          sx={{
            display: "flex",
            justifyContent: isMobileView ? "flex-start" : "flex-end",
            marginBottom: 2,
          }}
        >
          Ticket No
        </Typography>
        <Typography
          variant="h3"
          sx={{
            backgroundColor: grayLight,
            padding: 1,
            borderRadius: "5px",
            display: "inline",
          }}
        >
          {ticketNumber}
        </Typography>
      </Box>
    </Box>
  );
}

export default BoardingPassTicketNumber;
