import { useQuery } from "@apollo/client";
import { LOAD_UPCOMING_COMPANY_PASSENGER_TICKET_QUERY } from "globals/graphql";

const useLoadUpcomingCompanyPassengerTicket = () => {
  const { data: ticketData, loading } = useQuery(
    LOAD_UPCOMING_COMPANY_PASSENGER_TICKET_QUERY,
    {
      fetchPolicy: "cache-and-network",
      pollInterval: 1000 * 60 * 60 * 4, // poll every 4 hours - in the event a passenger leaves their home page open too long
    }
  );

  return {
    ticketData: ticketData?.loadUpcomingCompanyPassengerTicket,
    loading,
  };
};

export { useLoadUpcomingCompanyPassengerTicket };
