import { useCallback, useEffect, useMemo, useState } from "react";
import first from "lodash/first";
import last from "lodash/last";
import size from "lodash/size";

import circleSVG from "globals/design-system/illustrations/Circle.svg";
import { Stop } from "types";
import { moovsBlue } from "globals/design-system/colors";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { useSnackbar } from "globals/hooks";
import { createTripStopMarker, getStreetName } from "../../utils";

type UseAddStopsToMapProps = {
  map: google.maps.Map;
  stops: Stop[];
};

function useAddStopsToRouteMap(props: UseAddStopsToMapProps) {
  const { map, stops } = props;

  // hooks
  const snackbar = useSnackbar();
  // state
  const [tripStopMarkers, setTripStopMarkers] = useState<
    Map<string, google.maps.Marker>
  >(new Map());

  // memoize
  const { directionsService, directionsRenderer } = useMemo(() => {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      polylineOptions: { strokeColor: moovsBlue },
      preserveViewport: true, // prevents directions service to zoom out to cover all markers
    });
    directionsRenderer.setMap(map);
    return { directionsService, directionsRenderer };
  }, [map]);

  const tripStops = useMemo(() => {
    const stopsMap = new Map();
    stops.forEach((stop) => {
      stopsMap.set(stop.id, stop);
    });
    return stopsMap;
  }, [stops]);

  // helper functions
  const displayTripRoute = useCallback(
    async (routeData) => {
      const { firstStop, lastStop, wayPoints } = routeData;
      try {
        // create route path between all stops
        const directionsResult = await directionsService.route({
          origin: `${firstStop.coordinates.x},${firstStop.coordinates.y}`,
          destination: `${lastStop.coordinates.x},${lastStop.coordinates.y}`,
          waypoints: wayPoints,
          travelMode: google.maps.TravelMode.DRIVING,
        });
        directionsRenderer.setDirections(directionsResult);
      } catch (error) {
        const errorMessage =
          getErrorMessage(error) || "Error displaying route.";
        snackbar.error(errorMessage);
      }
    },
    [directionsRenderer, directionsService, snackbar]
  );

  const checkSameTripStops = useCallback(
    (prevTripStopsMap, currTripStopsMap) => {
      if (prevTripStopsMap.size !== currTripStopsMap.size) return false;

      let isSame = true;
      currTripStopsMap.forEach(
        ({ stopIndex: currStopIndex, coordinates: currCoords }, currStopId) => {
          if (!prevTripStopsMap.has(currStopId)) {
            isSame = false;
          } else {
            if (
              !hasSameCoordsandStopIndex(
                prevTripStopsMap,
                currStopId,
                currCoords,
                currStopIndex
              )
            ) {
              isSame = false;
            }
          }
        }
      );

      return isSame;
    },
    []
  );

  const hasSameCoordsandStopIndex = (
    prevTripStopsMap,
    currStopId,
    currCoords,
    currStopIndex
  ) => {
    const prevTripStop = prevTripStopsMap.get(currStopId);
    const prevLat = prevTripStop.getPosition().lat();
    const prevLng = prevTripStop.getPosition().lng();
    return (
      prevLat === currCoords?.x &&
      prevLng === currCoords?.y &&
      prevTripStop["stopIndex"] === currStopIndex
    );
  };

  const createTripMarkersAndWayPoints = async (stops, map) => {
    const tripStopMarkersMap = new Map();
    const bounds = new google.maps.LatLngBounds();

    // 1st stop
    const firstStop: Stop = first(stops);
    bounds.extend({
      lat: firstStop.coordinates.x,
      lng: firstStop.coordinates.y,
    });

    const streetName = getStreetName({
      stopLocation: firstStop.location,
      stoplocationAlias: firstStop.locationAlias,
    });
    tripStopMarkersMap.set(
      firstStop.id,
      await createTripStopMarker({
        map,
        stop: firstStop,
        svg: circleSVG,
        label: streetName,
        className: "route-stop-label",
      })
    );

    // last stop (office)
    const lastStop: Stop = last(stops);
    if (size(stops) === 2) {
      bounds.extend({
        lat: lastStop.coordinates.x,
        lng: lastStop.coordinates.y,
      });
    }
    const dropOffStreetName = getStreetName({
      stopLocation: lastStop.location,
      stoplocationAlias: lastStop.locationAlias,
    });
    tripStopMarkersMap.set(
      lastStop.id,
      await createTripStopMarker({
        map,
        stop: lastStop,
        svg: circleSVG,
        label: dropOffStreetName,
        className: "route-stop-label",
      })
    );

    // mid stops
    const wayPoints = [];
    if (stops.length > 2) {
      const midStops = stops.slice(1, stops.length - 1);
      midStops.forEach(async (midStop) => {
        bounds.extend({
          lat: midStop.coordinates.x,
          lng: midStop.coordinates.y,
        });

        wayPoints.push({
          location: `${midStop.coordinates?.x},${midStop.coordinates?.y}`,
          stopover: true,
        });

        const streetName = getStreetName({
          stopLocation: midStop.location,
          stoplocationAlias: midStop.locationAlias,
        });
        tripStopMarkersMap.set(
          midStop.id,
          await createTripStopMarker({
            map,
            stop: midStop,
            svg: circleSVG,
            label: streetName,
            className: "route-stop-label",
          })
        );
      });
    }

    map.setCenter(bounds.getCenter());
    map.fitBounds(bounds);

    return {
      tripStopMarkersMap,
      wayPoints,
      firstStop,
      lastStop,
    };
  };

  // create new markers if there is a change in trip stops
  useEffect(() => {
    const verify = async () => {
      // if there is a change in trip stops
      if (map && !checkSameTripStops(tripStopMarkers, tripStops)) {
        // remove all markers
        tripStopMarkers.forEach((tripStopMarker) => {
          tripStopMarker.setMap(null);
        });

        // create new markers
        const { wayPoints, tripStopMarkersMap, firstStop, lastStop } =
          await createTripMarkersAndWayPoints(stops, map);

        setTripStopMarkers(tripStopMarkersMap);

        // add route path to map
        await displayTripRoute({
          wayPoints,
          firstStop,
          lastStop,
        });
      }
    };
    verify();
  }, [
    map,
    stops,
    directionsRenderer,
    directionsService,
    tripStopMarkers,
    tripStops,
    displayTripRoute,
    checkSameTripStops,
  ]);
}

export default useAddStopsToRouteMap;
