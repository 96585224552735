import { createTheme, Components } from "@mui/material/styles";

import {
  errorRed,
  grayDark,
  grayMedium,
  grayLight,
  successGreen,
  moovsBlue,
  moovsBlueSelected,
  swoopGreen,
  warningYellow,
  white,
  black,
  granite,
  dudaWebsiteColors,
} from "./colors";

/* Custom Swoop Brand Colors */
export const primaryMainColor = moovsBlue;
export const primaryHoverColor = grayLight; //moovsBlueLight exists, but not used
export const primaryDisabledColor = moovsBlueSelected;

export const secondaryMainColor = swoopGreen;

/* Moovs zIndex Values */
export const confettiParticlesZIndex = 99999999;

/* Override mui Styles */
const components: Components = {
  MuiAutocomplete: {
    styleOverrides: {
      root: { width: "100%" },
      inputRoot: {
        backgroundColor: white,
      },
    },
  },
  MuiBadge: {
    styleOverrides: {
      colorError: {
        backgroundColor: errorRed,
        border: "1px solid #FFFFFF",
        fontSize: 11,
        lineHeight: 18,
        height: 18,
        minWidth: 18,
        padding: "0 4px",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      contained: {
        minHeight: 44,
        boxShadow:
          "0px 2px 3px -1px rgba(30, 30, 30, 0.1), 0px 8px 10px -2px rgba(30, 30, 30, 0.12)",
        "&:hover": {
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
        },
      },
      outlinedPrimary: {
        minHeight: 44,
        borderWidth: "2px",
        "&:hover": {
          borderWidth: "2px",
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: grayLight,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "& fieldset": {
          borderColor: grayMedium,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      outlined: {
        borderColor: grayLight,
        boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.03)",
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: 12,
        lineHeight: 1.5,
        padding: "8px 16px",
        backgroundColor: granite,
        borderRadius: 4,
      },
    },
  },
};

const theme = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1140, //1280
      xl: 1920,
    },
  },
  palette: {
    background: {
      default: white,
    },
    primary: {
      main: primaryMainColor,
      contrastText: "#fff",
    },
    secondary: {
      main: secondaryMainColor,
      contrastText: "#000",
    },
    error: { main: errorRed },
    warning: { main: warningYellow },
    success: { main: successGreen },
    action: {
      hover: primaryHoverColor,
    },
    text: {
      primary: black,
    },
    dudaDarkBlue: {
      main: dudaWebsiteColors.darkBlue,
      contrastText: "#fff",
    },
    dudaLightBlue: {
      main: dudaWebsiteColors.lightBlue,
      contrastText: "#fff",
    },
    dudaGreen: {
      main: dudaWebsiteColors.green,
      contrastText: "#fff",
    },
    dudaOrangeRed: {
      main: dudaWebsiteColors.orangeRed,
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Poppins",
    h1: {
      fontWeight: 600,
      fontSize: "2rem", // 32,
      lineHeight: 1.25,
      letterSpacing: "-0.031rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.5rem", // 24,
      lineHeight: 1.33,
      letterSpacing: "-0.031rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.25rem", // 20,
      lineHeight: 1.2,
      letterSpacing: "-0.009rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "1rem", // 16,
      lineHeight: 1.5,
      letterSpacing: "0.006rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "0.875rem", // 14,
      lineHeight: 1.43,
      letterSpacing: "0.025rem",
      textTransform: "uppercase",
    },
    h6: {
      fontWeight: 600,
      fontSize: "0.75rem", // 12,
      lineHeight: 1.5,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: "1rem", // 16,
      lineHeight: 1.5,
      letterSpacing: "-0.006rem",
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: "0.875rem", // 14,
      lineHeight: 1.43,
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem", // 16,
      lineHeight: 1.33,
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem", // 14,
      lineHeight: 1.43,
    },
    button: {
      textTransform: "none",
      fontWeight: 500,
      fontSize: "1rem", // 16,
      letterSpacing: "-0.016rem",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem", // 12,
      lineHeight: 1.5,
    },
    overline: {
      fontWeight: 500,
      fontSize: "0.75rem", // 12,
      lineHeight: 1.5,
      letterSpacing: "0.016rem",
      color: grayDark,
    },
  },
  spacing: 8,
  components,
});

export default theme;

// Adds Typescript support for extending theme color palette
// https://mui.com/customization/palette/#adding-new-colors
declare module "@mui/material/styles" {
  interface Palette {
    dudaDarkBlue: Palette["primary"];
    dudaGreen: Palette["primary"];
    dudaLightBlue: Palette["primary"];
    dudaOrangeRed: Palette["primary"];
  }
  interface PaletteOptions {
    dudaDarkBlue: PaletteOptions["primary"];
    dudaGreen: PaletteOptions["primary"];
    dudaLightBlue: PaletteOptions["primary"];
    dudaOrangeRed: PaletteOptions["primary"];
  }
}

// Adds Typescript support for using custom theme colors
// with Button component on `color` prop
// https://mui.com/customization/palette/#adding-new-colors
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    dudaOrangeRed: true;
    dudaLightBlue: true;
    dudaDarkBlue: true;
    dudaGreen: true;
  }
}
