// function that unmasks phone number coming from React Input Mask
// Example:  (828) 111 - 2020 will return 8281112020

export const unmaskPhone = (phoneNumber: string) =>
  phoneNumber
    .toString()
    .replace(/[+]/, "")
    .replace(/^1/, "")
    .replace(/\s/g, "")
    .replace(/[()]/g, "")
    .replace(/-/g, "");
