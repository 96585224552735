import React, { useMemo, useState } from "react";
import orderBy from "lodash/orderBy";

import { Box, CircularProgress } from "@mui/material";

import { Menu } from "components/ui";
import { useLoadCompanyShuttleRoutes } from "globals/hooks";
import { ConfirmSeatScreen } from "./components/ConfirmSeatScreen";
import { Stop } from "types";
import BookingRouteScreen from "./components/BookingRouteScreen";

export enum Screen {
  BookingRoute,
  ConfirmSeat,
}

export enum ShuttleTypes {
  PickUpAndReturn,
  PickUpOnly,
  ReturnOnly,
}

export type Location = {
  selectedLocation: string;
  officeLocation: string;
  selectedLocationAlias: string;
  officeLocationAlias: string;
};

export const TRIPS_PAGE_SCREEN_WIDTH = "780px";

function TripsPage() {
  // hooks
  const { companyShuttleRoutes, loading, refetchCompanyShuttleRoutes } =
    useLoadCompanyShuttleRoutes();

  // state
  const [currentScreen, setCurrentScreen] = useState(Screen.BookingRoute);
  const [location, setLocation] = useState<Location>({
    selectedLocation: null,
    officeLocation: null,
    selectedLocationAlias: null,
    officeLocationAlias: null,
  }); // updates when location is selected
  const [pickUpStops, setPickUpStops] = useState<Stop[]>([]); // updates when pickup time is selected
  const [returnStops, setReturnStops] = useState<Stop[]>([]); // updates when return time is selected

  const sortedCompanyShuttleRoutes = useMemo(
    () =>
      orderBy(companyShuttleRoutes, (route) => {
        const name = route.shuttleRoute ? route.shuttleRoute.name : "Other";
        return name.toLowerCase();
      }),
    [companyShuttleRoutes]
  );

  const screens = [
    <BookingRouteScreen
      pickUpStops={pickUpStops}
      returnStops={returnStops}
      location={location}
      setPickUpStops={setPickUpStops}
      setReturnStops={setReturnStops}
      setLocation={setLocation}
      setCurrentScreen={setCurrentScreen}
      companyShuttleRoutes={sortedCompanyShuttleRoutes}
    />,
    <ConfirmSeatScreen
      pickUpStops={pickUpStops}
      returnStops={returnStops}
      location={location}
      setCurrentScreen={setCurrentScreen}
      refetchCompanyShuttleRoutes={refetchCompanyShuttleRoutes}
    />,
  ];

  // return loading state
  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size={40} />
      </Box>
    );

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      {/* Header + Screen Body */}
      {screens[currentScreen]}

      {/* Footer - navigation menu */}
      <Menu />
    </Box>
  );
}

export default TripsPage;
