import React, { Dispatch, SetStateAction } from "react";
import moment from "moment";

import { Typography, Box } from "@mui/material";

import { Header } from "components/layout/components";
import { BackButton } from "components/ui/buttons";
import { useScreenSize } from "globals/hooks";
import { TRIPS_PAGE_SCREEN_WIDTH } from "pages/authorized/TripsPage/TripsPage";
import { Screen } from "../../../TripsPage";
import { white } from "globals/design-system/colors";

export const CONFIRM_HEADER_HEIGHT = "180px";

type ConfirmSeatHeaderProps = {
  dates: Date[];
  setCurrentScreen: Dispatch<SetStateAction<Screen>>;
  refetchCompanyShuttleRoutes: () => void;
};

function ConfirmSeatHeader(props: ConfirmSeatHeaderProps) {
  const { dates, setCurrentScreen, refetchCompanyShuttleRoutes } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // event handlers
  const handleBackClick = () => {
    refetchCompanyShuttleRoutes();
    setCurrentScreen(Screen.BookingRoute);
  };

  return (
    <Header heightPixels={CONFIRM_HEADER_HEIGHT} bgColor={white}>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={TRIPS_PAGE_SCREEN_WIDTH}
        mx={isMobileView ? 3 : 2}
        p={2}
      >
        {/* Back Button */}
        <Box>
          <BackButton onBackClick={handleBackClick} />
        </Box>

        {/* Dates */}
        <Typography variant="h2" mt={2}>
          {dates
            .map((date) => moment.utc(date).format("MMMM D, YYYY"))
            .join(", ")}
        </Typography>
      </Box>
    </Header>
  );
}

export default ConfirmSeatHeader;
