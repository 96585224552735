import React from "react";

import { Box, Typography } from "@mui/material";

import { errorRed, green } from "globals/design-system/colors";
import { CircleIcon } from "globals/design-system/icons";
import { Stop } from "types";

type AvailabilityTextProps = {
  available: boolean;
  singleDateSelected: boolean;
  stopsForGivenTime: Stop[];
};

function AvailabilityText(props: AvailabilityTextProps) {
  const { available, singleDateSelected, stopsForGivenTime } = props;

  // Only display available seats if there is a single date selected,
  // in which case there should only be 1 stop for each given timestamp
  const availableSeats = stopsForGivenTime[0].trip?.availableSeats;

  return !available ? (
    <Box>
      <Box display="flex" alignItems="center">
        <CircleIcon color={errorRed} />
        <Typography color={errorRed} variant="body2" ml={1} fontWeight={500}>
          {"No Seats Available"}
        </Typography>
      </Box>
    </Box>
  ) : singleDateSelected ? (
    <Box>
      <Box display="flex" alignItems="center">
        <CircleIcon color={green} />
        <Typography color={green} variant="body2" ml={1} fontWeight={500}>
          {`${availableSeats || ""} seats Available`}
        </Typography>
      </Box>
    </Box>
  ) : null;
}

export default AvailabilityText;
