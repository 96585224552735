import React from "react";
import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import Footer from "components/layout/components/Footer";
import {
  BookingsCalendarIcon,
  HomeIcon,
  TripsIcon,
} from "globals/design-system/icons";
import { grayDark, moovsBlue } from "globals/design-system/colors";
import { useLoadCompanyForUnauthenticated } from "globals/hooks";
import MenuButton from "./buttons/MenuButton";

function Menu() {
  //hooks
  const { pathname } = useLocation();
  const {
    unauthenticatedCompany: company,
    loading,
    error,
  } = useLoadCompanyForUnauthenticated();

  if (loading || error) return null;

  // derived state
  const homeActive = pathname.includes("/home");
  const tripsActive = pathname.includes("/trips");
  const bookingsActive =
    pathname.includes("/bookings") || pathname.includes("/boarding-pass");

  return (
    <Footer>
      <Box display="flex" width={1}>
        <MenuButton
          path={"/" + company.id + "/home"}
          active={homeActive}
          analyticsName="shuttleApp_homeViewed"
          icon={<HomeIcon color={homeActive ? moovsBlue : grayDark} />}
          label="Home"
        />

        <MenuButton
          path={"/" + company.id + "/trips"}
          active={tripsActive}
          analyticsName="shuttleApp_tripsViewed"
          icon={<TripsIcon color={tripsActive ? moovsBlue : grayDark} />}
          label="Trips"
        />

        <MenuButton
          path={"/" + company.id + "/bookings"}
          active={bookingsActive}
          icon={
            <BookingsCalendarIcon
              color={bookingsActive ? moovsBlue : grayDark}
            />
          }
          label="Bookings"
        />
      </Box>
    </Footer>
  );
}

export default Menu;
