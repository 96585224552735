/**
 * @file ticket.graphql.ts
 * queries/mutations for tickets
 */

import { gql } from "@apollo/client";

export const CONFIRM_SEAT_MUTATION = gql`
  mutation ConfirmCompanyPassengerTickets(
    $input: ConfirmCompanyPassengerTicketsInput!
  ) {
    confirmCompanyPassengerTickets(input: $input) {
      tickets {
        id
        ticketHolder {
          id
          firstName
          lastName
          mobilePhone
          company {
            id
            name
            companyLogoUrl
          }
        }
        ticketNumber
        trip {
          id
          requestId
          roundTripVariant
          timezoneId
          tripNumber
          returnTrip {
            id
            timezoneId
            tripNumber
          }
          tripCategory
        }
        stop {
          id
          stopIndex
          location
          locationAlias
          dateTime
          timezoneId
          pickUpDateTime
          pickUpTimezoneId
        }
      }
    }
  }
`;

export const LOAD_UPCOMING_COMPANY_PASSENGER_TICKET_QUERY = gql`
  query LoadUpcomingCompanyPassengerTicket {
    loadUpcomingCompanyPassengerTicket {
      trip {
        id
        requestId
        tripCategory
        stops {
          id
          stopIndex
          dateTime
          location
          locationAlias
        }
      }
      stop {
        id
        stopIndex
        location
        locationAlias
        dateTime
        coordinates
      }
    }
  }
`;

export const LOAD_DRIVER_TRACKING_QUERY = gql`
  query LoadUpcomingCompanyPassengerTicket {
    loadUpcomingCompanyPassengerTicket {
      trip {
        id
        requestId
        tripNumber
        temporaryPassenger
        tripCategory
        routes {
          id
          createdAt
          updatedAt
          dispatchStatus
          routeDispatch {
            id
            currentStatus
            currentStop {
              stopIndex
            }
            currentStopName
            currentStopArrivalTime
            driverEta
          }
          driver {
            id
            firstName
          }
        }
        stops {
          id
          stopIndex
          location
          locationAlias
          dateTime
          logisticStreetViewUrl
          note
          flightNumber
          flightData
          flightDataUpdatedAt
          airportIcao
          airlineIcao
          coordinates
          airport {
            icaoCode
            iataCode
            airportName
            countryName
            countryIso2
            timezone
            gmt
            coordinates
          }
          stopDispatch {
            arrivedAt
          }
        }
      }
    }
  }
`;

export const LOAD_COMPANY_PASSENGER_TICKETS_QUERY = gql`
  query LoadCompanyPassengerTickets {
    loadCompanyPassengerTickets {
      id
      ticketHolder {
        id
        firstName
        lastName
        mobilePhone
        company {
          id
          name
        }
      }
      ticketNumber
      stop {
        id
        stopIndex
        location
        locationAlias
        dateTime
        pickUpDateTime
      }
      trip {
        id
        tripCategory
      }
    }
  }
`;
