import React from "react";

import { Box, Divider, Typography } from "@mui/material";

import { alabaster } from "globals/design-system/colors";
import { useScreenSize } from "globals/hooks";
import GoogleMapsLocationButton from "components/ui/buttons/GoogleMapsLocationButton";

type BoardingPassLocationProps = {
  stopLocationText: string;
  stopLocation: string;
};

function BoardingPassLocation(props: BoardingPassLocationProps) {
  const { stopLocationText, stopLocation } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box mr={isMobileView ? 0 : "80px"}>
      <Typography variant="overline">Location</Typography>
      <Box display="flex" alignItems="center" mb={2} mt={0.5}>
        <GoogleMapsLocationButton
          location={stopLocation}
          locationText={stopLocationText}
        />
      </Box>
      {isMobileView ? (
        <Divider sx={{ backgroundColor: alabaster, marginBottom: 2 }} />
      ) : null}
    </Box>
  );
}

export default BoardingPassLocation;
