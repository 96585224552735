import React from "react";

type ArrowUpHalfIconProps = {
  color?: string;
  size?: string;
};

const ArrowUpHalfIcon = (props: ArrowUpHalfIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.757324 9.24309L9.24261 0.757812M9.24261 0.757812V9.24309M9.24261 0.757812H0.757324"
          stroke="#565656"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.19666 12.3019L11.8033 1.69531M11.8033 1.69531V12.3019M11.8033 1.69531H1.19666"
        stroke={color || "#195FE9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowUpHalfIcon;
