import React from "react";

import { Box } from "@mui/material";

import {
  useAuth,
  useLoadUpcomingCompanyPassengerTicket,
  useSnackbar,
} from "globals/hooks";
import authApi from "globals/hooks/useAuth/utils/authApi";
import { unmaskPhone } from "globals/utils/unmaskPhone";
import ReactInputVerificationCode from "react-input-verification-code";
import { useScreenSize } from "globals/hooks/useScreenSize";
import { Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

type VerifyCodeInputProps = {
  maskedPhone: string;
  companyId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

// constants
const MOBILE_STYLE = {
  "--ReactInputVerificationCode-itemWidth": "3rem",
  "--ReactInputVerificationCode-itemHeight": "3rem",
  "--ReactInputVerificationCode-itemSpacing": "0.6rem",
};

const DESKTOP_STYLE = {
  "--ReactInputVerificationCode-itemWidth": "66.4px",
  "--ReactInputVerificationCode-itemHeight": "60px",
  "--ReactInputVerificationCode-itemSpacing": "0.6rem",
};

function VerifyCodeInput(props: VerifyCodeInputProps) {
  const { maskedPhone, companyId, setLoading } = props;

  // hooks
  const snackbar = useSnackbar();
  const { isSmallTabletView } = useScreenSize();
  const { ticketData } = useLoadUpcomingCompanyPassengerTicket();
  const history = useHistory();
  const { onLogin } = useAuth();

  // event handlers
  const handleCodeChange = (code: string) => {
    if (code.length === 6) {
      login(code);
    }
  };

  const handleSendCodeAgain = () => sendCode();

  const login = async (code: string) => {
    setLoading(true);

    const error = await onLogin({ code, maskedPhone, companyId });

    setLoading(false);

    if (error) {
      snackbar.error("Sorry, the number you entered is invalid");
    } else {
      // Navigate to authenticated page
      if (ticketData) {
        history.push(`/${companyId}/home`);
      } else {
        history.push(`/${companyId}/trips`);
      }
    }
  };

  const sendCode = async () => {
    const { status } = await authApi.sendCode({
      companyId: companyId,
      mobilePhone: unmaskPhone(maskedPhone),
    });

    if (status !== 200) {
      snackbar.error("Could not send code");
    }
  };

  return (
    <Box sx={isSmallTabletView ? MOBILE_STYLE : DESKTOP_STYLE}>
      <div className="custom-verification-code-input">
        <ReactInputVerificationCode
          placeholder=""
          length={6}
          onChange={handleCodeChange}
        />
      </div>
      <Box display="flex" justifyContent="center" mt={3}>
        <Button onClick={handleSendCodeAgain} color="primary">
          <Typography variant="h4">Send code again</Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default VerifyCodeInput;
