import React from "react";

import { Box, Typography } from "@mui/material";
import { granite } from "globals/design-system/colors";
import { NoTrips } from "globals/design-system/illustrations";

function NoShuttleRoutesBlock() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="80vh"
    >
      <Box my={1}>
        <NoTrips />
      </Box>
      <Box my={1}>
        <Typography variant="h2" textAlign="center">
          No Shuttling Trips Available
        </Typography>
      </Box>
      <Typography variant="body1" textAlign="center" color={granite}>
        No upcoming trips available to book at this time.
      </Typography>
    </Box>
  );
}

export default NoShuttleRoutesBlock;
