import React from "react";

import { Box, Typography } from "@mui/material";

import { moovsBlue } from "globals/design-system/colors";
import { ArrowUpHalfIcon } from "globals/design-system/icons";

type GoogleMapsLocationButtonProps = {
  location: string;
  locationText: string;
};

function GoogleMapsLocationButton(props: GoogleMapsLocationButtonProps) {
  const { location, locationText } = props;

  const fallbackUrl = "https://www.google.com/maps";
  const mapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(
    location
  )}`;

  const handleOpenMaps = () => {
    if (!location) {
      window.open(fallbackUrl, "_blank");
    } else {
      window.open(mapsUrl, "_blank");
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      onClick={handleOpenMaps}
      sx={{ cursor: "pointer" }}
    >
      <Box>
        <Typography variant="h4" color={moovsBlue} sx={{ fontSize: 16 }}>
          {locationText || ""}
        </Typography>
      </Box>
      <Box width="24px" px={1}>
        <ArrowUpHalfIcon color={moovsBlue} />
      </Box>
    </Box>
  );
}

export default GoogleMapsLocationButton;
