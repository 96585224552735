import { useMutation } from "@apollo/client";

import { CONFIRM_SEAT_MUTATION } from "globals/graphql";
import { useSnackbar, useAnalytics, useLoadCompanyShuttleRoutes } from ".";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

const useConfirmSeat = () => {
  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const { refetchCompanyShuttleRoutes } = useLoadCompanyShuttleRoutes();

  const [confirmSeat, { data, loading, error }] = useMutation(
    CONFIRM_SEAT_MUTATION,
    {
      onCompleted() {
        track("shuttleApp_seatConfirmed");
        snackbar.success("Successfully confirmed seat!");
      },
      onError(error) {
        const errorMessage = getErrorMessage(error) || error.message;

        snackbar.error(errorMessage);

        refetchCompanyShuttleRoutes();
      },
    }
  );

  return { confirmSeat, data, loading, error };
};

export { useConfirmSeat };
