import React, { useMemo, useRef } from "react";
import { useInitializeMap } from "./hooks/useInitializeMap";
import useActiveDriverIcon from "./hooks/useActiveDriverIcon";
import { useTrackedDriver } from "./hooks/useTrackedDriver";
import useAddStopsToMap from "./hooks/useAddStopsToMap";
import { Ticket, Trip } from "types";

type MapProps = {
  trip: Trip;
  ticket: Ticket;
};

function Map(props: MapProps) {
  const { trip, ticket } = props;

  const googleMapRef = useRef();
  const { map } = useInitializeMap({ googleMapRef });
  useAddStopsToMap({ map, trip });

  const trips = useMemo(() => {
    return [trip];
  }, [trip]);

  const { trackedDriver } = useTrackedDriver(trips);

  useActiveDriverIcon({
    map,
    trackedDrivers: trackedDriver,
    trip,
    ticket,
  });

  return (
    <div
      style={{ width: "100%", height: "100%", borderRadius: "8px" }}
      id="map"
      ref={googleMapRef}
    />
  );
}

export default Map;
