import { fromBase64 } from "b64-lite";

export const fromGlobalId = (globalId: string) => {
  const unBasedGlobalId = fromBase64(globalId);
  const delimiterPos = unBasedGlobalId.indexOf(":");
  return {
    type: unBasedGlobalId.substring(0, delimiterPos),
    id: unBasedGlobalId.substring(delimiterPos + 1),
  };
};
