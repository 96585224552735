/**
 * Apollo Link documentation: https://www.apollographql.com/docs/react/api/link/introduction
 * 
 * handling request - 
 * syntax:

   // ...link logic going downstream (request)...
   return forward(operation) 

 * handling response:
 * syntax:

    // ...link logic going downstream (request)...
    return forward(operation).map((data) => {
      // ...link logic going upstream (response)...
    return data;
});
 */

import { ApolloLink } from "@apollo/client";

const headerMiddlewareLink = (companyId: string) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => {
      return {
        headers: {
          ...headers,
          companyId,
        },
      };
    });

    return forward(operation);
  });

export { headerMiddlewareLink };
