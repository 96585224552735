import React, { useEffect, useState } from "react";
import size from "lodash/size";

import { Box, Button } from "@mui/material";

import {
  SelectDates,
  SelectLocation,
  SelectRoute,
  SelectTripType,
} from "./components";
import { CompanyShuttleRoute, ShuttleStopLocation, Stop } from "types";
import { SelectedDateData } from "./components/SelectDates";
import { Screen, ShuttleTypes, Location } from "../../../../TripsPage";

type BookingRouteFormProps = {
  pickUpStops: Stop[];
  returnStops: Stop[];
  location: Location;
  setPickUpStops: React.Dispatch<React.SetStateAction<Stop[]>>;
  setReturnStops: React.Dispatch<React.SetStateAction<Stop[]>>;
  companyShuttleRoutes: CompanyShuttleRoute[];
  setCurrentScreen: React.Dispatch<React.SetStateAction<Screen>>;
  selectedCompanyShuttleRoute: CompanyShuttleRoute;
  setSelectedCompanyShuttleRoute: React.Dispatch<
    React.SetStateAction<CompanyShuttleRoute>
  >;
  selectedPickupLocation: ShuttleStopLocation;
  setSelectedPickupLocation: React.Dispatch<
    React.SetStateAction<ShuttleStopLocation>
  >;
};

function BookingRouteForm(props: BookingRouteFormProps) {
  const {
    pickUpStops,
    returnStops,
    location,
    setPickUpStops,
    setReturnStops,
    companyShuttleRoutes,
    setCurrentScreen,
    selectedCompanyShuttleRoute,
    setSelectedCompanyShuttleRoute,
    selectedPickupLocation,
    setSelectedPickupLocation,
  } = props;

  // state
  const [selectedDates, setSelectedDates] = useState<SelectedDateData[]>([]);
  const [shuttleType, setShuttleType] = useState(ShuttleTypes.PickUpAndReturn);

  // effects
  // reset date when new route or location is selected
  useEffect(() => {
    if (selectedCompanyShuttleRoute || selectedPickupLocation) {
      setSelectedDates([]);
    }
  }, [selectedCompanyShuttleRoute, selectedPickupLocation]);

  // event handler
  const handleNextClick = () => {
    setCurrentScreen(Screen.ConfirmSeat);
  };

  const hasSomePickUpStops = !!size(pickUpStops);
  const hasSomeReturnStops = !!size(returnStops);

  // derived state
  const readyToConfirm = !!(
    location.officeLocation &&
    location.selectedLocation &&
    ((shuttleType === ShuttleTypes.PickUpAndReturn &&
      hasSomePickUpStops &&
      hasSomeReturnStops) ||
      (shuttleType === ShuttleTypes.PickUpOnly && hasSomePickUpStops) ||
      (shuttleType === ShuttleTypes.ReturnOnly && hasSomeReturnStops))
  );

  return (
    <Box mt={2}>
      <SelectRoute
        companyShuttleRoutes={companyShuttleRoutes}
        selectedCompanyShuttleRoute={selectedCompanyShuttleRoute}
        setSelectedCompanyShuttleRoute={setSelectedCompanyShuttleRoute}
      />

      <SelectLocation
        selectedCompanyShuttleRoute={selectedCompanyShuttleRoute}
        setSelectedPickupLocation={setSelectedPickupLocation}
        selectedPickupLocation={selectedPickupLocation}
      />

      <SelectDates
        selectedPickupLocation={selectedPickupLocation}
        setSelectedDates={setSelectedDates}
      />

      <SelectTripType
        selectedDates={selectedDates}
        selectedPickupLocation={selectedPickupLocation}
        selectedCompanyShuttleRoute={selectedCompanyShuttleRoute}
        setShuttleType={setShuttleType}
        shuttleType={shuttleType}
        setPickUpStops={setPickUpStops}
        setReturnStops={setReturnStops}
      />

      <Box mb={2} mt={4}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleNextClick}
          disabled={!readyToConfirm}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default BookingRouteForm;
