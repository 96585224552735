/**
 * @file MoovsApolloProvider.tsx
 * Migration from Apollo Boost to Apollo Client to enable advanced configurations of Apollo Link.
 * Adds operator slug to header for backend authentication on requests.
 * For a more complex implementation see operator app.
 *
 * components:
 *  MoovsApolloProvider
 */
import React from "react";
import {
  InMemoryCache,
  ApolloClient,
  ApolloLink,
  HttpLink,
  ApolloProvider,
} from "@apollo/client";

import { headerMiddlewareLink } from "./ApolloLinks";
import introspection from "../possibleTypes";
import { useCompanyId } from "globals/hooks/useCompanyId";

const cache = new InMemoryCache({
  possibleTypes: introspection.possibleTypes,
});

const client = (companyId: string) =>
  new ApolloClient({
    link: ApolloLink.from([
      headerMiddlewareLink(companyId),
      new HttpLink({
        uri: `${process.env.REACT_APP_API_URL}/shuttle`,
        credentials: "include",
      }),
    ]),
    connectToDevTools: process.env.NODE_ENV !== "production",
    cache,
  });

function MoovsApolloProvider({ children }: { children: React.ReactNode }) {
  const companyId = useCompanyId();

  return <ApolloProvider client={client(companyId)}>{children}</ApolloProvider>;
}

export default MoovsApolloProvider;
