import React from "react";

import { Button } from "@mui/material";

import { moovsBlue } from "globals/design-system/colors";
import { ArrowLeftIcon } from "globals/design-system/icons";

type BackButtonProps = {
  onBackClick: () => void;
};

function BackButton(props: BackButtonProps) {
  const { onBackClick } = props;
  return (
    <Button
      sx={{ paddingLeft: 0 }}
      onClick={onBackClick}
      color="primary"
      startIcon={<ArrowLeftIcon color={moovsBlue} size="small" />}
    >
      Back
    </Button>
  );
}

export default BackButton;
