import React from "react";
import { Control, Controller, FieldErrorsImpl } from "react-hook-form";

import { TextField } from "@mui/material";

type RHFTextFieldProps = {
  name: string;
  control: Control<any>;
  errors: FieldErrorsImpl<{
    firstName: string;
    lastName: string;
    mobile: string;
  }>;

  // All other TextField props
  [x: string]: any;
};

function RHFTextField(props: RHFTextFieldProps) {
  const { name, control, errors, ...textFieldProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const allProps = { ...field, ...textFieldProps };
        return (
          <TextField
            {...allProps}
            fullWidth
            error={!!errors[name]}
            helperText={errors[name]?.message}
          />
        );
      }}
    />
  );
}

export default RHFTextField;
