import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import { Box, ButtonBase, Typography } from "@mui/material";
import { useAnalytics } from "globals/hooks";

type MenuButtonProps = {
  path: string;
  active: boolean;
  icon: ReactNode;
  label: string;
  analyticsName?: string;
};

function MenuButton(props: MenuButtonProps) {
  const { path, active, icon, label, analyticsName } = props;

  // hooks
  const { track } = useAnalytics();

  // event handlers
  const handleClick = () => {
    analyticsName && track(analyticsName);
  };

  return (
    <ButtonBase
      component={Link}
      to={path}
      onClick={handleClick}
      disableTouchRipple
      sx={{ display: "flex", flex: 1 }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        color={active ? "#195FE9" : "#999999"}
      >
        {icon}
        <Typography
          sx={{
            size: "9px",
            paddingTop: "5px",
          }}
        >
          {label}
        </Typography>
      </Box>
    </ButtonBase>
  );
}

export default MenuButton;
