import { useCallback, useEffect, useMemo, useState } from "react";

import { ShuttleStopLocation } from "types";
import { createTripStopMarker, getStreetName } from "../../utils";
import googleMarkerSVG from "globals/design-system/illustrations/driverTracking/GoogleMarker.svg";

type useAddStopsToMarkerMapProps = {
  map: google.maps.Map;
  stops: ShuttleStopLocation[];
};

function useAddStopsToMarkerMap(props: useAddStopsToMarkerMapProps) {
  const { map, stops } = props;

  // state
  const [tripStopMarkers, setTripStopMarkers] = useState<
    Map<string, google.maps.Marker>
  >(new Map());

  const tripStops = useMemo(() => {
    const stopsMap = new Map();
    stops.forEach((stop) => {
      stopsMap.set(stop.id, stop);
    });
    return stopsMap;
  }, [stops]);

  // helper functions
  const checkSameTripStops = useCallback(
    (prevTripStopsMap, currTripStopsMap) => {
      if (prevTripStopsMap.size !== currTripStopsMap.size) return false;

      let isSame = true;
      currTripStopsMap.forEach(
        ({ stopIndex: currStopIndex, coordinates: currCoords }, currStopId) => {
          if (!prevTripStopsMap.has(currStopId)) {
            isSame = false;
          } else {
            if (
              !hasSameCoordsandStopIndex(
                prevTripStopsMap,
                currStopId,
                currCoords,
                currStopIndex
              )
            ) {
              isSame = false;
            }
          }
        }
      );

      return isSame;
    },
    []
  );

  const hasSameCoordsandStopIndex = (
    prevTripStopsMap,
    currStopId,
    currCoords,
    currStopIndex
  ) => {
    const prevTripStop = prevTripStopsMap.get(currStopId);
    const prevLat = prevTripStop.getPosition().lat();
    const prevLng = prevTripStop.getPosition().lng();

    return (
      prevLat === currCoords?.x &&
      prevLng === currCoords?.y &&
      prevTripStop["stopIndex"] === currStopIndex
    );
  };

  const createTripMarkersAndWayPoints = async (stops, map) => {
    const tripStopMarkersMap = new Map();
    const bounds = new google.maps.LatLngBounds();

    stops.forEach(async (stop) => {
      bounds.extend({
        lat: stop.coordinates.x,
        lng: stop.coordinates.y,
      });

      const streetName = getStreetName({
        stopLocation: stop.location,
        stoplocationAlias: stop.locationAlias,
      });
      tripStopMarkersMap.set(
        stop.id,
        await createTripStopMarker({
          stop,
          map,
          svg: googleMarkerSVG,
          label: streetName,
          labelOrigin: new google.maps.Point(16, -20),
          scaledSize: new google.maps.Size(25, 25),
          className: "pick-up-location-label",
        })
      );
    });

    map.setCenter(bounds.getCenter());
    map.fitBounds(bounds);
    map.setZoom(18);

    return {
      tripStopMarkersMap,
    };
  };

  // create new markers if there is a change in trip stops
  useEffect(() => {
    const verify = async () => {
      // if there is a change in trip stops
      if (map && !checkSameTripStops(tripStopMarkers, tripStops)) {
        // remove all markers
        tripStopMarkers.forEach((tripStopMarker) => {
          tripStopMarker.setMap(null);
        });

        // create new markers
        const { tripStopMarkersMap } = await createTripMarkersAndWayPoints(
          stops,
          map
        );

        setTripStopMarkers(tripStopMarkersMap);
      }
    };
    verify();
  }, [map, stops, tripStopMarkers, tripStops, checkSameTripStops]);
}

export default useAddStopsToMarkerMap;
