import React from "react";
import moment from "moment";

import { Box, Card, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  alabaster,
  errorRed,
  granite,
  white,
} from "globals/design-system/colors";
import { ArrowReturnIcon, ArrowUpHalfIcon } from "globals/design-system/icons";
import { useScreenSize } from "globals/hooks";
import { Ticket, TripCategory } from "types";
import { ShuttleTypes } from "../../TripsPage/TripsPage";
import BoardingPassDetail from "./BoardingPassDetail";
import BoardingPassLocation from "./BoardingPassLocation";
import BoardingPassTicketNumber from "./BoardingPassTicketNumber";

type BoardingPassProps = {
  ticket: Ticket;
  onCancelClick: () => void;
  btnLoading: boolean;
};

function BoardingPassBody(props: BoardingPassProps) {
  const { ticket, onCancelClick, btnLoading } = props;

  const stopLocationText = ticket.stop.locationAlias || ticket.stop.location;

  // hooks
  const { isMobileView } = useScreenSize();

  if (!ticket) return null;

  // derived state
  const shuttleType =
    ticket.trip.tripCategory === TripCategory.ShuttlePickUp
      ? ShuttleTypes.PickUpOnly
      : ShuttleTypes.ReturnOnly;

  return (
    <Box>
      {/* Boarding Pass Header */}
      <Card
        variant="outlined"
        sx={{
          margin: 1,
        }}
      >
        {/* Boarding Pass Ticket Number Section */}
        <BoardingPassTicketNumber ticketNumber={ticket.ticketNumber} />

        {/* Details Section */}
        <Box sx={{ backgroundColor: white, marginTop: 2, padding: 3 }}>
          <Box display="flex" flexDirection={isMobileView ? "column" : "row"}>
            <Box width={isMobileView ? "100%" : "30%"}>
              {/* Trip Type */}
              <BoardingPassDetail
                overline="Trip Type"
                subtitle={
                  shuttleType === ShuttleTypes.PickUpOnly
                    ? "Pick-Up Trip"
                    : "Return Trip"
                }
                icon={
                  shuttleType === ShuttleTypes.PickUpOnly ? (
                    <ArrowUpHalfIcon color={granite} size="small" />
                  ) : (
                    <ArrowReturnIcon color={granite} size="small" />
                  )
                }
              />
            </Box>
            <Box width={isMobileView ? "100%" : "70%"}>
              <Box
                display="flex"
                flexDirection={isMobileView ? "column" : "row"}
              >
                {/* Date */}
                <BoardingPassDetail
                  overline="Date"
                  subtitle={moment
                    .utc(
                      shuttleType === ShuttleTypes.PickUpOnly
                        ? ticket.stop.dateTime
                        : ticket.stop.pickUpDateTime
                    )
                    .format("MMMM DD, YYYY")}
                />

                {/* Time */}
                <BoardingPassDetail
                  overline={
                    shuttleType === ShuttleTypes.PickUpOnly
                      ? "Pick-Up Time"
                      : "Return Time"
                  }
                  subtitle={moment
                    .utc(
                      shuttleType === ShuttleTypes.PickUpOnly
                        ? ticket.stop.dateTime
                        : ticket.stop.pickUpDateTime
                    )
                    .format("h:mm a")}
                />
              </Box>
            </Box>
          </Box>

          {/* Desktop Divider */}
          {isMobileView ? null : (
            <Divider
              sx={{
                backgroundColor: alabaster,
                marginBottom: 3,
                marginTop: 2.5,
              }}
            />
          )}

          <Box display="flex" flexDirection={isMobileView ? "column" : "row"}>
            <Box width={isMobileView ? "100%" : "30%"}>
              {/* Location/ Name */}
              {isMobileView ? (
                <BoardingPassLocation
                  stopLocationText={stopLocationText}
                  stopLocation={ticket.stop.location}
                />
              ) : (
                <BoardingPassDetail
                  overline="Full Name"
                  subtitle={`${ticket.ticketHolder.firstName} ${ticket.ticketHolder.lastName}`}
                  noDivider
                />
              )}
            </Box>

            <Box width={isMobileView ? "100%" : "70%"}>
              {/* Location/ Name */}
              {isMobileView ? (
                <BoardingPassDetail
                  overline="Full Name"
                  subtitle={`${ticket.ticketHolder.firstName} ${ticket.ticketHolder.lastName}`}
                  noDivider
                />
              ) : (
                <BoardingPassLocation
                  stopLocationText={stopLocationText}
                  stopLocation={ticket.stop.location}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Card>

      <Box
        display="flex"
        sx={{
          margin: 1,
        }}
      >
        <LoadingButton
          variant="contained"
          onClick={onCancelClick}
          loading={btnLoading}
          sx={{
            backgroundColor: errorRed,
            width: isMobileView ? "100%" : null,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          Cancel Seat
        </LoadingButton>
      </Box>
    </Box>
  );
}
export default BoardingPassBody;
