/**
 * @file companyPassenger.graphql.ts
 * queries/mutations for company passengers.
 *
 * company passengers are members of companies that are riding shuttles
 */

import { gql } from "@apollo/client";

export const LOAD_ME_QUERY = gql`
  query loadMe {
    loadMe {
      id
      firstName
      lastName
      mobilePhone
      company {
        id
        createdAt
        updatedAt
        name
        contactsTotalCount
        companyLogoUrl
        contacts {
          id
          createdAt
          updatedAt
          firstName
          lastName
          email
          mobilePhone
          mobilePhoneInternational
        }
      }
    }
  }
`;
