import React, { useMemo } from "react";
import every from "lodash/every";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { CompanyShuttleRoute } from "types";

type SelectRouteProps = {
  companyShuttleRoutes: CompanyShuttleRoute[];
  setSelectedCompanyShuttleRoute: React.Dispatch<
    React.SetStateAction<CompanyShuttleRoute>
  >;
  selectedCompanyShuttleRoute: CompanyShuttleRoute;
};

function SelectRoute(props: SelectRouteProps) {
  const {
    selectedCompanyShuttleRoute,
    companyShuttleRoutes,
    setSelectedCompanyShuttleRoute,
  } = props;

  // event handler
  const handleSelectRouteChange = (e) => {
    const { value } = e.target;
    setSelectedCompanyShuttleRoute(value);
  };

  // memoize
  // if all routes don't have shuttle routes, route dropdown is disabled
  const allEmptyShuttleRoutes = useMemo(
    () => every(companyShuttleRoutes, (route) => !route.shuttleRoute),
    [companyShuttleRoutes]
  );

  return (
    <Box>
      <Typography variant="h4">Select Route</Typography>
      {allEmptyShuttleRoutes ? (
        <FormControl fullWidth disabled>
          <InputLabel id="no-route-label">None</InputLabel>
          <Select labelId="no-route-label" id="no-route" label="None" value="">
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          </Select>
        </FormControl>
      ) : (
        <FormControl fullWidth>
          <Select
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 140,
                  overflow: "scroll",
                },
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            }}
            value={
              selectedCompanyShuttleRoute === null
                ? ""
                : selectedCompanyShuttleRoute
            }
            inputProps={{ "aria-label": "Without label" }}
            onChange={handleSelectRouteChange}
          >
            {companyShuttleRoutes.map((route) => {
              return (
                //@ts-ignore - necessary to load object into value
                <MenuItem value={route} key={route.id}>
                  {route.shuttleRoute?.name || "Other"}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}

export default SelectRoute;
