import { white } from "globals/design-system/colors";
import { Stop } from "types";

export const createTripStopMarker = (
  stop: Stop,
  map: google.maps.Map,
  svg: string,
  label: number | null
) => {
  const { coordinates, stopIndex } = stop;

  const tripLocationMarker = new google.maps.Marker({
    position: { lat: coordinates.x, lng: coordinates.y },
    map: map,
    icon: {
      url: svg,
      anchor: new google.maps.Point(12, 20),
      labelOrigin: new google.maps.Point(17, 15),
      scaledSize: new google.maps.Size(label ? 35 : 50, label ? 35 : 50),
    },
    label: label
      ? {
          text: label.toString(),
          color: white,
          fontWeight: "600",
          fontSize: "12px",
          fontFamily: "Poppins",
        }
      : null,
  });
  tripLocationMarker.set("stopIndex", stopIndex);

  return tripLocationMarker;
};
