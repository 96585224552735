import React from "react";
import { useHistory } from "react-router-dom";

import { Link, Typography } from "@mui/material";

import { grayDark } from "globals/design-system/colors";

type RedirectPageOptionProps = {
  statement: string;
  alternateName: string;
  alternatePath: string;
};

function RedirectPageOption(props: RedirectPageOptionProps) {
  const { statement, alternateName, alternatePath } = props;

  // hooks
  const history = useHistory();

  return (
    <Typography
      variant="body2"
      align="center"
      style={{ color: grayDark, fontWeight: 400 }}
    >
      {statement}{" "}
      <Link
        component="button"
        underline="none"
        variant="body1"
        color="primary"
        style={{ fontWeight: 500, marginLeft: 5, fontSize: "14px" }}
        onClick={() => {
          history.push(alternatePath);
        }}
      >
        {alternateName}
      </Link>
    </Typography>
  );
}

export default RedirectPageOption;
