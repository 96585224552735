import { useQuery } from "@apollo/client";

import { LOAD_COMPANY_PASSENGER_TICKETS_QUERY } from "globals/graphql";
import { Ticket } from "types";

const useLoadCompanyPassengerTickets = () => {
  const {
    data: ticketsData,
    loading,
    error,
    refetch: refetchTickets,
  } = useQuery<{ loadCompanyPassengerTickets: Ticket[] }>(
    LOAD_COMPANY_PASSENGER_TICKETS_QUERY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  return {
    tickets: ticketsData?.loadCompanyPassengerTickets || [],
    loading,
    error,
    refetchTickets,
  };
};

export { useLoadCompanyPassengerTickets };
